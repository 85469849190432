import {Bindings} from "data/constants/bindings";
import {GroupStatus, ModalType} from "data/enums";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IAnswer, IGroup, IPropsWithNavigate} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {GroupHelper} from "data/utils/GroupHelper";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import type {NavigateFunction} from "react-router-dom";

export interface INotAllAnswersModalController extends ViewController<IPropsWithNavigate> {
	viewSummary: () => void;
	answerQuestions: () => void;

	get isOpen(): boolean;

	get totalQuestions(): number;

	get totalAnswers(): number;
}

@injectable()
export class NotAllAnswersModalController implements INotAllAnswersModalController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.getIsModalOpen(ModalType.NOT_ALL_ANSWERS);
	}

	get totalAnswers(): number {
		return this.answersForAvailableGroups.length;
	}

	get totalQuestions(): number {
		return this.availableGroupsForAnswer.length;
	}

	protected get availableGroupsForAnswer(): IGroup[] {
		return this._eventsStore.groups.filter((e) => e.status === GroupStatus.Scheduled);
	}

	protected get availableGroupsForAnswerIds(): number[] {
		return this.availableGroupsForAnswer.map((group) => group.groupId);
	}

	protected get answersForAvailableGroups(): IAnswer[] {
		return this._answerStore.localAnswers.filter((group) =>
			this.availableGroupsForAnswerIds.includes(group.groupId)
		);
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IPropsWithNavigate): void {
		this._navigate = param.navigate;

		const subscription = reaction(
			() => this.isOpen,
			() => {
				if (!this.isOpen) {
					return;
				}
				if (this.totalAnswers === this.totalQuestions) {
					this.viewSummary();
					this.close();
				}
			}
		);

		this._subscriptions$.push(subscription);
	}

	public viewSummary = () => {
		this._navigate?.("/summary");
		this.close();
	};

	public answerQuestions = () => {
		this._navigate?.("/?edit=true");
		setTimeout(() => {
			this._answerStore.clearTieBreaker();
			this._answerStore.sliderStep = this.getNearestStep();
			this.close();
		}, 500);
	};

	protected close() {
		this._modalsStore.hideModal();
	}

	protected getNearestStep(): number {
		const nearestGroupIndex = this._eventsStore.groups.findIndex((e, _index) => {
			return (
				GroupHelper.IS_GROUP_SCHEDULED(e) &&
				!this._answerStore.getAnswerByGroupId(e.groupId)
			);
		});

		if (nearestGroupIndex === -1) {
			return this._eventsStore.groups.length - 1;
		}

		return nearestGroupIndex;
	}
}
