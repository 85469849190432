import {Bindings} from "data/constants/bindings";
import {GroupStatus} from "data/enums";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {IAnswer, ICorrectAnswer, IGroup, ISummaryItemParams} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

export interface ISummaryItemAnswerController extends ViewController<ISummaryItemParams> {
	get hasAnswer(): boolean;

	get hasAnswerForPlayer(): boolean;

	get hasAnswerForMissGreen(): boolean;

	get isAnswerIncorrect(): boolean;

	get player(): IPlayer | undefined;

	get correctPlayer(): IPlayer | undefined;

	get isLocked(): boolean;

	get answerClass(): string;

	get isMissGreenAnswerCorrect(): boolean;
}

@injectable()
export class SummaryItemAnswerController implements ISummaryItemAnswerController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;
	@observable private _localAnswer: IAnswer | undefined;

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore
	) {
		makeAutoObservable(this);
	}

	get player() {
		if (!this.answer?.player) {
			return undefined;
		}
		return this._playersStore.getPlayerById(this.answer?.player);
	}

	get hasAnswerForPlayer() {
		return Boolean(this.answer?.player);
	}

	get hasAnswerForMissGreen() {
		return this.answer?.player === null || this.answer?.player === 0;
	}

	get answer(): IAnswer | undefined {
		if (!this._groupId) {
			return undefined;
		}
		if (this._localAnswer) {
			return this._localAnswer;
		}
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	get isAnswerIncorrect(): boolean {
		if (!this.hasAnswer || !this.isComplete) {
			return false;
		}
		return this.correctAnswer?.player !== this.answer?.player;
	}

	get hasAnswer(): boolean {
		return Boolean(this.answer) || Boolean(this._localAnswer);
	}

	get isLocked(): boolean {
		if (!this.group) {
			return false;
		}
		return [GroupStatus.Active, GroupStatus.Completed].includes(this.group.status);
	}

	get answerClass(): string {
		if (!this.answer || !this.correctAnswer) {
			return "";
		}
		return this.answer.player === this.correctAnswer.player ? "correct" : "incorrect";
	}

	get isMissGreenAnswerCorrect() {
		return this.correctAnswer?.player === null;
	}

	get correctPlayer(): IPlayer | undefined {
		if (!this.correctAnswer?.player) {
			return undefined;
		}
		return this._playersStore.getPlayerById(this.correctAnswer.player || 0);
	}

	protected get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	protected get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	protected get isComplete(): boolean {
		return this.group?.status === GroupStatus.Completed;
	}

	dispose(): void {
		return;
	}

	init(param: ISummaryItemParams): void {
		this._groupId = param.groupId;
		this._localAnswer = param.answer;
	}
}
