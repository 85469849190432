import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import * as React from "react";
import {ModalType} from "data/enums";

export interface ITermsModalController extends ViewController {
	acceptTerms: () => void;
	checkboxHandler: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

	get isOpen(): boolean;

	get isTermsAccepted(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class TermsModalController implements ITermsModalController {
	@observable private _isTermsAccepted: boolean = false;
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.getIsModalOpen(ModalType.TERMS);
	}

	get isTermsAccepted(): boolean {
		return this._isTermsAccepted;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public acceptTerms = (): void => {
		this._isLoading = true;
		this._userStore
			.acceptTerms()
			.then(() => this.close())
			.catch(console.log);
	};

	@action
	public checkboxHandler = (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	): void => {
		this._isTermsAccepted = checked;
	};

	protected close() {
		this._modalsStore.hideModal();
	}
}
