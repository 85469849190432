import {Bindings} from "data/constants/bindings";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import type {ILeaderboardApiProvider} from "data/providers/api/leaderboard.api.provider";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IGameBarData, IGameBarPayload} from "data/types/entities";
import type {AxiosApiErrorGeneric} from "data/types/api";

export interface IGameBarStore {
	fetchGameBar(): Promise<void>;
	get gameBarData(): IGameBarData | undefined;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	@observable private _gameBarData: IGameBarData | undefined;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaderboardApiProvider)
		private _leaderboardApiProvider: ILeaderboardApiProvider
	) {
		makeAutoObservable(this);
	}

	public async fetchGameBar(): Promise<void> {
		const payload: IGameBarPayload = {
			roundNo: this._eventsStore.roundId,
			eventId: this._eventsStore.nearestEvent?.id || 0,
		};

		try {
			const {data} = await this._leaderboardApiProvider.roundGameBar(payload);
			runInAction(() => {
				this._gameBarData = data.success;
			});
			return Promise.resolve();
		} catch (e) {
			this._modalsStore.showAxiosError(e as AxiosApiErrorGeneric);
			return Promise.reject();
		}
	}

	get gameBarData(): IGameBarData | undefined {
		return this._gameBarData;
	}
}
