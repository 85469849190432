import {ThemeOptions} from "@mui/material/styles";

export const accordionTheme: ThemeOptions = {
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					boxShadow: "0 0 4px rgba(0, 0, 0, 0.15)",
					".MuiAccordionSummary-content": {
						margin: 0,
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						gap: "8px",
					},
					".MuiAccordionSummary-content.Mui-expanded": {
						margin: 0,
					},
					".MuiAccordionSummary-root.Mui-expanded": {
						minHeight: "unset",
						maxHeight: "48px",
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					background: "linear-gradient(116.84deg, #0C0385 7.26%, #000FE1 90.14%)",
					color: "#FFFFFF",
					padding: "12px 16px",
					margin: 0,

					"&.Mui-expanded": {
						borderRadius: "4px 4px 0 0",
					},

					svg: {
						color: "#FFFFFF",
					},

					".expand-icon": {
						transition: "0.25s",
					},
					".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
						transform: "none",
					},
					".MuiAccordionSummary-expandIconWrapper.Mui-expanded .expand-icon": {
						transform: "rotate(180deg)",
					},
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					borderRadius: "0 0 4px 4px",
					padding: "16px",
					background: "#FFFFFF",
					color: "#000000",
					margin: 0,
				},
			},
		},
	},
};
