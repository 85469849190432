import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {DateTime} from "luxon";
import {Bindings} from "data/constants/bindings";
import {RoundStatus} from "data/enums";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IGameBarData, IGroup, IRoundEntity} from "data/types/entities";
import type {IGameBarStore} from "data/stores/game_bar/game_bar.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface ISummaryRoundSelectorController extends ViewController {
	navigateToNextRound: () => void;
	navigateToPrevRound: () => void;

	get round(): IRoundEntity | undefined;

	get eventDate(): string;

	get isNextDisabled(): boolean;

	get isPrevDisabled(): boolean;

	get isStatsVisible(): boolean;

	get gameBarData(): IGameBarData | undefined;
}

@injectable()
export class SummaryRoundSelectorController implements ISummaryRoundSelectorController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	public get isNextDisabled(): boolean {
		if (!this.round || !this.nextRound) {
			return true;
		}

		const isCurrentLastOne = [RoundStatus.Scheduled, RoundStatus.Playing].includes(
			this.round.status
		);

		if (isCurrentLastOne) {
			return true;
		}

		if (this.nextRound.status === RoundStatus.Scheduled && this.isCurrentRoundComplete) {
			return false;
		}

		const length = this._eventsStore.rounds.length;
		return this.round.roundNo === length;
	}

	public get isPrevDisabled(): boolean {
		if (!this.round) {
			return true;
		}
		return this.round?.roundNo === 1;
	}

	public get round(): IRoundEntity | undefined {
		return this._eventsStore.selectedRound;
	}

	public get eventDate() {
		if (this.firstGroup) {
			return DateTime.fromISO(this.firstGroup.date).toFormat("cccc, dd LLLL yyyy");
		}

		const event = this._eventsStore.nearestEvent;

		if (!event) {
			return "-";
		}
		// Wednesday, 12th July 2023
		return DateTime.fromISO(event.dateStart).toFormat("cccc, dd LLLL yyyy");
	}

	public get isStatsVisible(): boolean {
		return true;
	}

	get gameBarData(): IGameBarData | undefined {
		return this._gameBarStore.gameBarData;
	}

	private get isCurrentRoundComplete() {
		return this.round?.status === RoundStatus.Complete;
	}

	private get nextRound() {
		if (!this.round) {
			return undefined;
		}
		return this._eventsStore.getRoundByRoundNo(this.round.roundNo + 1);
	}

	private get firstGroup(): IGroup | undefined {
		return this._eventsStore.groups[0];
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.fetchGameBar();
		const subscription = reaction(
			() => [this.round, this._eventsStore.nearestEvent],
			() => this.fetchGameBar()
		);
		this._subscriptions$.push(subscription);
	}

	public navigateToNextRound = () => {
		const roundNo = this.round?.roundNo;
		if (!roundNo) {
			return;
		}
		this._eventsStore.selectRoundByRoundNo(roundNo + 1);
	};

	public navigateToPrevRound = () => {
		const roundNo = this.round?.roundNo;
		if (!roundNo) {
			return;
		}
		this._eventsStore.selectRoundByRoundNo(roundNo - 1);
	};

	private fetchGameBar() {
		if (!this._eventsStore.rounds.length || !this._eventsStore.nearestEvent) {
			return;
		}

		void this._gameBarStore.fetchGameBar();
	}
}
