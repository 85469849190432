import {action, type IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import {noop} from "lodash";

@injectable()
export class HOCMainController implements ViewController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _eventForPlayers: number | undefined;
	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: void): void {
		this._eventsStore.initializeDataFetch();

		this._subscriptions$.push(
			reaction(
				() => [this._eventsStore.nearestEvent],
				() => {
					void this.fetchPlayers();
					if (this._eventsStore.nearestEvent) {
						void this._eventsStore.fetchCurrentEvent().catch(noop);
					}
				}
			)
		);
	}

	@action
	protected fetchPlayers() {
		const eventId = this._eventsStore.nearestEvent?.id;
		if (!eventId || this._eventForPlayers === eventId) {
			return;
		}
		this._eventForPlayers = eventId;
		void this._playersStore.fetchPlayersForEvent(eventId).catch(noop);
	}
}
