import {getIsUserRegisteredForGame, trackUser} from "data/utils/helpers";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAuthApiProvider} from "data/providers/api/auth.api.provider";
import type {IUserApiProvider, IUpdateUserPayload} from "data/providers/api/user.api.provider";
import type {
	IForgotPasswordPayload,
	IPasswordApiProvider,
	IResetPasswordPayload,
} from "data/providers/api/password.api.provider";
import type {AxiosResponse} from "axios";
import {Language} from "data/enums";
import type {IUserLoginPayload} from "data/types/user";
import {GAME_NAME, GAME_SEASON} from "data/constants";

export interface IRegisteredGameEntity {
	season: number;
	terms: boolean;
}

export interface IRegisteredGame {
	game: string;
	seasons: IRegisteredGameEntity[];
}

export interface IUser {
	autopick: boolean;
	country: string;
	createdAt: string;
	email: string;
	firstname: string;
	id: number;
	isNotificationsEnabled: boolean;
	lastname: string;
	opt_in: boolean;
	registeredGames: IRegisteredGame[];
	username: string;
}

export interface IUserStore {
	get user(): IUser | undefined;
	get isAuthorized(): boolean;
	get isRegisteredForGame(): boolean;
	get wasLoggedOut(): boolean;
	get isSessionChecked(): boolean;

	forgotPassword(payload: IForgotPasswordPayload): Promise<AxiosResponse<void>>;
	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse<void>>;
	update(payload: Partial<IUpdateUserPayload>): Promise<void>;
	deactivate(lang: Language): Promise<void>;
	login(payload: IUserLoginPayload): Promise<void>;
	register(payload: IUserLoginPayload): Promise<void>;
	acceptTerms(): Promise<void>;
	logout(): Promise<void>;
	requestUser(): Promise<void | IUser>;
}

@injectable()
export class UserStore implements IUserStore {
	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}
	@observable private _user?: IUser = undefined;
	@observable private _wasLoggedOut = false;
	@observable private _isSessionChecked: boolean = false;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get isRegisteredForGame() {
		return getIsUserRegisteredForGame(this.user, GAME_NAME, GAME_SEASON);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	@action
	async requestUser() {
		try {
			const response = await this._userApi.user();
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
			});
			return Promise.resolve(user);
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isSessionChecked = true;
			});
		}
	}

	@action
	async login(payload: IUserLoginPayload) {
		try {
			console.log(payload);
			const response = await this._authApi.login(payload);
			const {user} = response.data.success;

			void trackUser({
				event: "login",
				user_details: {
					user_id: payload.uid,
					user_emid: user.email,
				},
			});
			runInAction(() => {
				this._user = user;
				this._wasLoggedOut = false;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	async register(payload: IUserLoginPayload) {
		const response = await this._userApi.register(payload);
		const {user} = response.data.success;

		void trackUser({
			event: "sign_up",
			user_details: {
				user_id: payload.uid,
				user_emid: user.email,
			},
		});
		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: Partial<IUpdateUserPayload>) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate(lang: Language) {
		await this._userApi.deactivate_account(lang);

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async acceptTerms() {
		try {
			const payload = {
				game: GAME_NAME,
				terms: true,
			};
			const response = await this._userApi.register_for_game(payload);
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	forgotPassword(payload: IForgotPasswordPayload) {
		return this._passwordApi.forgotPassword(payload);
	}

	resetPassword(payload: IResetPasswordPayload) {
		return this._passwordApi.resetPassword(payload);
	}
}
