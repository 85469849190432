import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRoundWatchFetchPayload, IRoundWatchSavePayload} from "data/types/round-watch";
import type {IRoundWatchApiProvider} from "data/providers/api/round_watch.provider";

export interface ILiveWatchingStore {
	get isRoundWatched(): boolean;

	fetchRoundWatchData(payload: IRoundWatchFetchPayload): Promise<void>;

	saveRoundWatched(payload: IRoundWatchSavePayload): Promise<void>;
}

@injectable()
export class LiveWatchingStore implements ILiveWatchingStore {
	@observable private _isRoundWatched: boolean = true;

	constructor(@inject(Bindings.RoundWatchApiProvider) private _api: IRoundWatchApiProvider) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	@action
	public async fetchRoundWatchData(payload: IRoundWatchFetchPayload): Promise<void> {
		this._isRoundWatched = true;

		try {
			const {data} = await this._api.fetchRoundWatch(payload);
			const roundEntity = data.success.watching.find((e) => e.round === payload.roundNo);
			runInAction(() => {
				this._isRoundWatched = Boolean(roundEntity);
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}

	get isRoundWatched(): boolean {
		return this._isRoundWatched;
	}

	public async saveRoundWatched(payload: IRoundWatchSavePayload): Promise<void> {
		try {
			await this._api.saveRoundWatch(payload);
			runInAction(() => {
				this._isRoundWatched = true;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
