import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import "assets/css/gigya.css";

import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {BrowserTracing, Event, init, Integrations, Replay} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {HOCMain} from "views/components/HOCMain/HOCMain.component";
import {theme} from "assets/theming/theme";
import {ThemeProvider} from "@mui/material";
import {GigyaGate} from "views/components/gigyaGate/gigyaGate.component";
import {OffSeasonCheck} from "views/components/offseason/off_season_check.component";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import {TutorialModal} from "views/components/modals/tutorialModal/tutorialModal.component";

if (SENTRY_DSN_URL) {
	let errorCount = 0;
	const MAX_ERRORS = 100;
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", "ntp.dpworldtour.com"],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
		beforeSend: (event: Event) => {
			errorCount++;
			if (errorCount > MAX_ERRORS) {
				return null;
			}
			return event;
		},
		// ignoreErrors: [
		// 	"no active event found"
		// ]
	});
}
const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

createRoot(root).render(
	<React.StrictMode>
		<InjectionProvider container={DIContainer}>
			<ThemeProvider theme={theme}>
				<Bootstrap>
					<BrowserRouter basename={BASE_URL}>
						<Suspense fallback={<PagePreloader />}>
							<HOCMain>
								<GigyaGate>
									{SecretGateController.IS_SECRET_PASSED ? (
										<OffSeasonCheck>
											<React.Fragment>
												<Header />
												<RootRoutes />
												<TutorialModal />
												<Footer />
											</React.Fragment>
										</OffSeasonCheck>
									) : (
										<SecretGateRoutes />
									)}
								</GigyaGate>
							</HOCMain>
						</Suspense>
					</BrowserRouter>
				</Bootstrap>
			</ThemeProvider>
		</InjectionProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
