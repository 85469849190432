import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import {IAnswer, ICorrectAnswer, IGroup} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {GroupHelper} from "data/utils/GroupHelper";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {GroupStatus} from "data/enums";

interface IParams {
	playerId: number;
	groupId: number;
}

export interface IAnswerButtonController extends ViewController<IParams> {
	get answer(): IAnswer | undefined;

	get player(): IPlayer | undefined;

	get selected(): boolean;

	get isLive(): boolean;

	get isAnswered(): boolean;

	get completeClass(): string;

	get isCorrect(): boolean;

	get isIncorrect(): boolean;

	get answeredClass(): string;

	get correctClass(): string;

	get incorrectClass(): string;
}

@injectable()
export class AnswerButtonController implements IAnswerButtonController {
	@observable private _playerId: number = Number.MAX_SAFE_INTEGER;
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) private _answerStore: IAnswerStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	get player() {
		return this._playersStore.getPlayerById(this._playerId);
	}

	get selected(): boolean {
		return this.answer?.player === this._playerId;
	}

	get answeredClass(): string {
		return this.isAnswered ? "answered" : "";
	}

	get correctClass(): string {
		return this.isCorrectOption ? "correct" : "";
	}

	get incorrectClass(): string {
		if (!this.isAnswered) {
			return "";
		}
		return this.isCorrect ? "" : "incorrect";
	}

	get isLive(): boolean {
		return this.group?.status === GroupStatus.Active;
	}

	get isAnswered(): boolean {
		return this.answer?.player === this._playerId;
	}

	get isCorrect(): boolean {
		return this.correctAnswer?.player === this.answer?.player && this.isComplete;
	}

	get isIncorrect(): boolean {
		return this.correctAnswer?.player !== this.answer?.player && this.isComplete;
	}

	get answer(): IAnswer | undefined {
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	get completeClass(): string {
		return this.isComplete ? "complete" : "";
	}

	protected get isComplete(): boolean {
		return GroupHelper.IS_GROUP_COMPLETE(this.group);
	}

	protected get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	protected get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	// Check is option (not answer) is correct
	protected get isCorrectOption(): boolean {
		return this._playerId === this.correctAnswer?.player;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._playerId = param.playerId;
		this._groupId = param.groupId;
	}
}
