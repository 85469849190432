import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IGroup, IIncompleteAnswerModalData} from "data/types/entities";
import {GroupStatus, ModalType} from "data/enums";

export interface IContestSliderController extends ViewController {
	get sliderStep(): number;
	get isTieBreaker(): boolean;
	get groups(): IGroup[];
	updateStep: (step: number) => void;
	checkAnswer: (currentSlide: number, nextSlide: number) => void;
}

@injectable()
export class ContestSliderController implements IContestSliderController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get sliderStep(): number {
		return this._answerStore.sliderStep;
	}

	get groups(): IGroup[] {
		return this._eventsStore.groups;
	}

	get isTieBreaker(): boolean {
		return this._answerStore.isTieBreaker;
	}

	public updateStep = (step: number) => {
		this._answerStore.sliderStep = step;
	};

	public checkAnswer = (currentSlide: number, nextSlide: number) => {
		// Prevent swipe left on first slide
		if (currentSlide === nextSlide) {
			return;
		}

		const group = this._eventsStore.groups[currentSlide];
		if (!group || [GroupStatus.Completed, GroupStatus.Active].includes(group.status)) {
			return;
		}

		const answer = this._answerStore.getAnswerByGroupId(group.groupId);
		if (!answer) {
			return;
		}

		const isAnswerIncomplete = this._answerStore.getIsAnswerIncompleteByGroupId(group.groupId);

		if (isAnswerIncomplete) {
			const modalData: IIncompleteAnswerModalData = {
				nextStep: nextSlide,
				prevStep: currentSlide,
				groupId: group.groupId,
			};
			this._modalsStore.showModal(ModalType.INCOMPLETE_ANSWER, modalData);
		}
	};
}
