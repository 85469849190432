import React from "react";
import {observer} from "mobx-react";
import {Button, Modal} from "@mui/material";
import {
	CloseButton,
	ModalButtons,
	ModalContainer,
	ModalText,
	ModalTitle,
} from "views/components/modals/common";
import {ReactComponent as SuccessRound} from "assets/img/icons/success_round.svg";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ISuccessModalController} from "views/components/modals/successModal/successModal.controller";

export const SuccessModalComponent: React.FC = observer(() => {
	const {modalData, close, isOpen} = useViewController<ISuccessModalController>(
		Bindings.SuccessModalController
	);

	return (
		<Modal open={isOpen}>
			<ModalContainer>
				<CloseButton onClick={close} />
				<SuccessRound className="success-icon" />

				<ModalTitle>{modalData?.title}</ModalTitle>
				<ModalText>{modalData?.message}</ModalText>

				<ModalButtons>
					<Button variant="contained" onClick={close}>
						Close
					</Button>
				</ModalButtons>
			</ModalContainer>
		</Modal>
	);
});
