import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";

export interface IPlayer {
	above_cut_off: boolean;
	country: string;
	dpwt_rank: number;
	dpwt_wins: number | null;
	event_stats: unknown[];
	firstname: string;
	id: number;
	is_dpwt_member: boolean;
	lastname: string;
	stats: unknown[];
	world_rank: number;
}

export interface IPlayersStore {
	get getIsLoading(): boolean;
	get list(): IPlayer[];
	getPlayerById(playerId: number): IPlayer | undefined;
	fetchPlayersForEvent(eventId: number): Promise<void>;
	fetchPlayers(): Promise<void>;
}

@injectable()
export class PlayersStore implements IPlayersStore {
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _list: IPlayer[] = [];

	get list() {
		return this._list;
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getPlayerById(playerId: number): IPlayer | undefined {
		return this._list.find((player) => player.id === playerId);
	}

	@action
	async fetchPlayers() {
		const {data} = await this._jsonProvider.players();

		runInAction(() => {
			this._list = data;
		});
	}

	@action
	async fetchPlayersForEvent(eventId: number) {
		const {data} = await this._jsonProvider.playersForEvent(eventId);

		runInAction(() => {
			this._list = data;
		});
	}
}
