import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {ITutorialModalController} from "views/components/modals/tutorialModal/tutorialModal.controller";
import {Bindings} from "data/constants/bindings";
import {CloseButton, ModalContainer} from "views/components/modals/common";
import {Button, Modal} from "@mui/material";
import styled from "@emotion/styled";
import Slider from "react-slick";
import {getIsIOS} from "data/utils";
import {TutorialSteps} from "views/components/modals/tutorialModal/tutorialSteps.component";
import {Exist} from "views/components/exist/exist.component";

const SModalContainer = styled(ModalContainer)`
	overflow: hidden;
	padding-left: 0;
	padding-right: 0;
	justify-content: space-between;
	.close {
		right: 10px;
		top: 10px;
	}
`;

const SliderWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.start {
		width: calc(100% - 40px);
		margin: auto auto 0 auto;
	}

	.slick-arrow {
		display: none !important;
	}
`;

const StepContent = styled.div`
	img {
		width: 100%;
		height: auto;
	}
	
	> div {
		padding-left: 20px;
		padding-right: 20px;
	}
	h3 {
		color: var(--text-dark-blue);
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 18px */
		text-transform: uppercase;
		margin-bottom: 12px;
		margin-top: 20px;
	}
	
	p {
		color: var(--text-dark-blue);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /
	}
`;

export const TutorialModal: React.FC = observer(() => {
	const sliderRef = useRef<Slider>(null);

	const {isOpen, steps, currentStep, isFinalStep, goToStep, close} =
		useViewController<ITutorialModalController>(Bindings.TutorialModalController);

	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.slickGoTo(currentStep);
		}
	}, [currentStep, sliderRef]);

	return (
		<Modal open={isOpen}>
			<SModalContainer>
				<CloseButton onClick={close} className="dark close" />
				<SliderWrapper>
					<Slider
						ref={sliderRef}
						dots={false}
						infinite={false}
						speed={500}
						slidesToScroll={1}
						slidesToShow={1}
						useTransform={getIsIOS()}
						afterChange={goToStep}>
						{steps.map((step, index) => (
							<StepContent key={index}>
								<img src={step.image} alt={`step-${index}`} />
								<div>
									<h3>{step.title}</h3>
									<p>{step.text}</p>
								</div>
							</StepContent>
						))}
					</Slider>
					<Exist when={isFinalStep}>
						<Button fullWidth onClick={close} variant="contained" className="start">
							Get Started
						</Button>
					</Exist>
					<Exist when={!isFinalStep}>
						<TutorialSteps
							currentStep={currentStep}
							steps={steps}
							goToStep={goToStep}
						/>
					</Exist>
				</SliderWrapper>
			</SModalContainer>
		</Modal>
	);
});
