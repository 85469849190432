import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {GroupStatus} from "data/enums";
import type {IAnswer, IGroup, IOnlyGroupId} from "data/types/entities";
import type {IEventsStore} from "data/stores/events/events.store";
import type {Empty} from "data/types/generics";
import type {IAnswerStore} from "data/stores/answer/answer.store";

export interface IAccordionPillController extends ViewController<IOnlyGroupId> {
	get isOpen(): boolean;
	get isLive(): boolean;
	get isComplete(): boolean;
	get answer(): IAnswer | undefined;
}

@injectable()
export class AccordionPillController implements IAccordionPillController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;
	constructor(
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: IOnlyGroupId): void {
		this._groupId = param.groupId;
	}

	private get group(): Empty<IGroup> {
		return this._eventsStore.getGroupById(this._groupId);
	}

	public get answer(): IAnswer | undefined {
		if (!this._groupId) {
			return undefined;
		}
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	public get isComplete(): boolean {
		return this.group?.status === GroupStatus.Completed;
	}

	public get isLive(): boolean {
		return this.group?.status === GroupStatus.Active;
	}

	public get isOpen(): boolean {
		return this.group?.status === GroupStatus.Scheduled;
	}
}
