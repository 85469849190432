import {GroupHelper} from "data/utils/GroupHelper";
import React from "react";
import {
	action,
	computed,
	type IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {
	Distance,
	HTMLElementWithValue,
	IAnswer,
	ICorrectAnswer,
	IGroup,
} from "data/types/entities";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import {ModalType} from "data/enums";

interface IParams {
	groupId: number;
}

export interface IVoteAccordionController extends ViewController<IParams> {
	handleDistanceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleGroupValueChange: (event: React.MouseEvent<HTMLElement>, value: number) => void;
	openInfoModal: () => void;

	get groupValue(): number | undefined | null;

	get distanceValue(): Distance;

	get group(): IGroup | undefined;

	get groupsLength(): number;

	get isLocked(): boolean;

	get isComplete(): boolean;

	get isLive(): boolean;

	get isMissAllAnswered(): boolean;

	get isMissAllCorrect(): boolean;

	get isMissAllIncorrect(): boolean;

	get missCorrectClass(): string;

	get missCompleteClass(): string;

	get missAnsweredClass(): string;

	get missIncorrectClass(): string;

	get lockedClass(): string;
}

@injectable()
export class VoteAccordionController implements IVoteAccordionController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable protected _groupId: number | undefined;
	@observable protected _groupValue: number | null | undefined;
	@observable protected _distanceValue: Distance = "";

	constructor(
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	get groupValue(): number | undefined | null {
		if (this.answer?.player === null) {
			return 0;
		}
		return this.answer?.player;
	}

	get groupsLength(): number {
		return this._eventsStore.groupsLength;
	}

	get distanceValue(): Distance {
		return this.answer?.distance || "";
	}

	get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	get isComplete(): boolean {
		if (!this.group) {
			return false;
		}
		return GroupHelper.IS_GROUP_COMPLETE(this.group);
	}

	get isLive(): boolean {
		if (!this.group) {
			return false;
		}
		return GroupHelper.IS_GROUP_LIVE(this.group);
	}

	get isLocked(): boolean {
		if (!this.group) {
			return true;
		}
		return GroupHelper.IS_GROUP_LOCKED(this.group);
	}

	get isMissAllAnswered(): boolean {
		return this.answer?.player === 0;
	}

	get isMissAllCorrect(): boolean {
		if (!this.isComplete) {
			return false;
		}

		return this.correctAnswer?.player === null;
	}

	get isMissAllIncorrect(): boolean {
		if (!this.isMissAllAnswered || !this.isComplete) {
			return false;
		}

		return this.parsedAnswer !== this.correctAnswer?.player;
	}

	get missCorrectClass(): string {
		return this.isMissAllCorrect ? "correct" : "";
	}

	get missCompleteClass(): string {
		return GroupHelper.IS_GROUP_COMPLETE(this.group) ? "complete" : "";
	}

	get missAnsweredClass(): string {
		return this.isMissAllAnswered ? "answered" : "";
	}

	get missIncorrectClass(): string {
		return this.isMissAllAnswered && this.isMissAllIncorrect ? "incorrect" : "";
	}

	get lockedClass(): string {
		return this.isLocked ? "locked" : "";
	}

	protected get answer(): IAnswer | undefined {
		if (!this._groupId) {
			return undefined;
		}
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	@computed
	protected get isAnswerNotFull(): boolean {
		if (!this._groupId) {
			return true;
		}

		if (!this.distanceValue && this.groupValue === undefined) {
			return true;
		}
		return Boolean(this.groupValue && !this.distanceValue);
	}

	protected get parsedAnswer() {
		return this.answer?.player === 0 ? null : this.answer?.player;
	}

	protected get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: IParams): void {
		this._groupId = param.groupId;

		this._subscriptions$.push(
			reaction(
				() => [this._groupValue, this._distanceValue],
				() => this.saveLocalAnswer()
			)
		);

		this._subscriptions$.push(
			reaction(
				() => this.answer,
				() => {
					if (!this.answer) {
						return;
					}

					if (this._groupValue !== this.answer.player) {
						this._groupValue = this.answer.player;
					}

					if (this._distanceValue !== this.answer.distance) {
						this._distanceValue = this.answer.distance;
					}
				}
			)
		);
	}

	@action
	public handleDistanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this._distanceValue = (event.target.value as Distance) || "";
	};

	@action
	public handleGroupValueChange = (event: React.MouseEvent<HTMLElement>, _value: number) => {
		const target = event.target as HTMLElementWithValue;
		this._groupValue = Number(target.value);
	};

	public openInfoModal = () => {
		this._modalsStore.showModal(ModalType.GROUP_INFO);
	};

	protected saveLocalAnswer() {
		this.checkAnswerConsistency();

		if (!this._groupId) {
			return;
		}

		const payload: IAnswer = {
			groupId: this._groupId,
			distance: this._distanceValue,
			player: Number(this._groupValue) ?? null,
		};
		this._answerStore.saveAnswer(payload);
	}

	@action
	protected checkAnswerConsistency(): void {
		if (!this.groupValue && this.distanceValue) {
			this._distanceValue = "";
		}
	}
}
