import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";

import footerBackground from "assets/img/footer.jpg";
import {ReactComponent as Logo} from "assets/img/logo_stack.svg";
import {ReactComponent as GeniusLogo} from "assets/img/genius_logo.svg";
import {NavLink} from "react-router-dom";
import {Sharing} from "views/components/sharing/sharing.component";

const Wrapper = styled.footer`
	margin-top: auto;
	padding: 24px;
	background: url("${footerBackground}");
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	color: #ffffff;
	width: 100%;

	button svg {
		color: #ffffff;
	}

	p,
	a {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		text-align: center;
	}

	.share-icon {
		svg {
			margin-left: -2px;
		}
	}
`;

const Links = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Footer: React.FC = observer(() => {
	const year = new Date().getFullYear();
	return (
		<Wrapper>
			<Logo />
			<Sharing buttonType="icon" className="share-icon" />

			<Links>
				<NavLink to="/help/t&c's">Terms and Conditions</NavLink>
				<NavLink to="/help/contact_us">Contact Us</NavLink>
				<a href="https://www.europeantour.com/cookies" target="_blank" rel="noreferrer">
					Cookies
				</a>
			</Links>

			<a href="https://geniussports.com/media-engagement/" target="_blank" rel="noreferrer">
				<GeniusLogo />
			</a>

			<p>© DP World Tour Nearest The Pin {year}</p>
		</Wrapper>
	);
});
