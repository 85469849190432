import {Bindings} from "data/constants/bindings";
import {GroupStatus} from "data/enums";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import {IAnswer, IGroup, IPropsWithNavigate} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import type {NavigateFunction} from "react-router-dom";

interface IParams extends IPropsWithNavigate {
	groupId: number;
}

export interface ISummaryItemController extends ViewController<IParams> {
	goToSliderStep: (index: number) => void;

	get group(): IGroup | undefined;

	get answer(): IAnswer | undefined;

	get isComplete(): boolean;

	get groupsLength(): number;

	get answerClass(): string;
}

@injectable()
export class SummaryItemController implements ISummaryItemController {
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _groupId: number | undefined;

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	get isComplete(): boolean {
		return this.group?.status === GroupStatus.Completed;
	}

	get answer(): IAnswer | undefined {
		if (!this._groupId) {
			return undefined;
		}
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	get answerClass(): string {
		let className = "";

		if (this.answer) {
			className += "answered ";
		}
		if (this.isLocked) {
			className += "locked ";
		}
		if (this.isComplete) {
			className += "completed ";
		}
		return className;
	}

	get groupsLength(): number {
		return this._eventsStore.groupsLength;
	}

	private get isLocked(): boolean {
		if (!this.group) {
			return false;
		}
		return [GroupStatus.Active, GroupStatus.Completed].includes(this.group.status);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._groupId = param.groupId;
		this._navigate = param.navigate;
	}

	public goToSliderStep = (index: number): void => {
		this._answerStore.clearTieBreaker();
		this._answerStore.sliderStep = index;
		this._navigate?.("/?edit=true");
	};
}
