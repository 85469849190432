import type {IGeneralStore} from "data/stores/general/general.store";
import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {IGameEvent} from "data/types/entities";

export interface IHeaderController extends ViewController {
	toggleMenu: () => void;
	openTutorial: () => void;

	get isMenuOpen(): boolean;
	get isInSeason(): boolean;
	get settings(): IGameEvent["contestSettings"];
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(
		@inject(Bindings.GeneralStore) private _generalStore: IGeneralStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	get settings() {
		return this._eventsStore.settings;
	}

	get isMenuOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	get isInSeason(): boolean {
		return this._generalStore.getIsInSeason;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public toggleMenu = (): void => {
		this._menuStore.toggleMenu();
	};

	public openTutorial = (): void => {
		this._tutorialStore.openTutorialModal();
	};
}
