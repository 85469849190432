import styled from "@emotion/styled";

export const Container = styled.div`
	margin: 0 auto;
	width: 100%;
	max-width: 1240px;
	padding: 0 20px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 24px;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

export const Content = styled.div`
	width: 100%;
	height: auto;
`;

export const Aside = styled.div`
	width: 384px;
	height: auto;

	@media screen and (max-width: 768px) {
		width: 100%;
		padding: 20px;
	}
`;

export const BottomSheet = styled.div`
	background: #ffffff;
	max-width: 100%;
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3;
	height: 68px;
	padding: 9px 20px;
	box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);

	@media screen and (max-width: 768px) {
		padding: 12px 20px;
	}
`;
