import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	action,
	IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";
import type {ILiveWatchingStore} from "data/stores/live_watching/live_watching.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface IRoundPlayModalController extends ViewController {
	get isOpen(): boolean;

	get isLoading(): boolean;

	onNo: () => void;
	onYes: () => void;
}

@injectable()
export class RoundPlayModalController implements IRoundPlayModalController {
	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.LiveWatchingStore) private _liveWatchingStore: ILiveWatchingStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(): void {
		this.fetchModalInfo();

		const subscription = reaction(
			() => [this.eventId, this.roundNo],
			() => this.fetchModalInfo()
		);
		this._subscriptions$.push(subscription);
	}

	get isOpen(): boolean {
		return !this._liveWatchingStore.isRoundWatched;
	}

	public onNo = () => {
		this.close(0);
	};

	public onYes = () => {
		this.close(1);
	};

	@action
	private close(watching: 1 | 0) {
		if (!this.eventId || !this.roundNo) {
			return;
		}
		this._isLoading = true;
		this._liveWatchingStore
			.saveRoundWatched({
				eventId: this.eventId,
				roundNo: this.roundNo,
				watching,
			})
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
				this._modalsStore.showModal(ModalType.SUCCESS, {
					message: "Thanks for answering, we hope you enjoy playing Nearest The Pin!",
				});
			});
	}

	private get eventId(): number | undefined {
		return this._eventsStore.nearestEvent?.id;
	}

	private get roundNo(): number | undefined {
		if (!this._eventsStore.rounds.length) {
			return undefined;
		}
		return this._eventsStore.roundId;
	}

	private fetchModalInfo() {
		if (!this.eventId || !this.roundNo) {
			return;
		}
		void this._liveWatchingStore.fetchRoundWatchData({
			eventId: this.eventId,
			roundNo: this.roundNo,
		});
	}
}
