import {ThemeOptions} from "@mui/material/styles";

export const dividerTheme: ThemeOptions = {
	components: {
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: "rgba(196, 194, 222, 1)",
				},
			},
		},
	},
};
