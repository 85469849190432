import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGigyaStore} from "data/stores/gigya/gigya.store";

export interface IAuthModalControllerController extends ViewController {
	get isOpen(): boolean;
	onClose: () => void;

	toLogin: () => void;
	toRegister: () => void;
}

@injectable()
export class AuthModalControllerController implements IAuthModalControllerController {
	constructor(@inject(Bindings.GigyaStore) private _gigyaStore: IGigyaStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onClose = (): void => {
		this._gigyaStore.closeAuthModal();
	};

	get isOpen(): boolean {
		return this._gigyaStore.isAuthModalOpen;
	}

	toLogin = (): void => {
		this._gigyaStore.openLoginModal();
		this.onClose();
	};

	toRegister = (): void => {
		this._gigyaStore.openRegisterModal();
		this.onClose();
	};
}
