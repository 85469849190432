import React from "react";
import {observer} from "mobx-react";
import {ModalContainer, ModalText, ModalTitle} from "views/components/modals/common";
import {Button, Checkbox, FormControlLabel, Modal} from "@mui/material";
import {ReactComponent as WarningRound} from "assets/img/icons/warning_round.svg";
import {useViewController} from "data/services/locator";
import {ITermsModalController} from "views/components/modals/termsModal/termsModal.controller";
import {Bindings} from "data/constants/bindings";

export const TermsModal: React.FC = observer(() => {
	const {isOpen, isTermsAccepted, isLoading, acceptTerms, checkboxHandler} =
		useViewController<ITermsModalController>(Bindings.TermsModalController);
	return (
		<Modal open={isOpen}>
			<ModalContainer>
				<WarningRound className="danger-icon" />
				<ModalTitle>Terms & Conditions</ModalTitle>
				<ModalText>
					To be eligible to win prizes, you must accept this game’s terms and conditions.
				</ModalText>

				<FormControlLabel
					control={<Checkbox checked={isTermsAccepted} onChange={checkboxHandler} />}
					label="Accept Terms & Conditions"
				/>

				<Button
					fullWidth
					onClick={acceptTerms}
					disabled={!isTermsAccepted || isLoading}
					variant="contained">
					Accept
				</Button>
			</ModalContainer>
		</Modal>
	);
});
