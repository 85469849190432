import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import {IIncompleteAnswerModalData} from "data/types/entities";

export interface IIncompleteAnswerModalController extends ViewController {
	get isOpen(): boolean;
	cancelNavigation: () => void;
	confirmNavigation: () => void;
}

@injectable()
export class IncompleteAnswerModalController implements IIncompleteAnswerModalController {
	constructor(
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	protected get modalData(): IIncompleteAnswerModalData {
		return this._modalsStore.modalContent as IIncompleteAnswerModalData;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public cancelNavigation = (): void => {
		const prevStep = this.modalData.prevStep;
		if (prevStep !== undefined) {
			setTimeout(() => {
				this._answerStore.sliderStep = prevStep;
			}, 500);
		}
		this.close();
	};

	public confirmNavigation = (): void => {
		if (this.modalData.groupId) {
			this._answerStore.removeAnswerByGroupId(this.modalData.groupId);
		}
		this.close();
	};

	protected close() {
		this._modalsStore.hideModal();
	}

	get isOpen(): boolean {
		return this._modalsStore.getIsModalOpen(ModalType.INCOMPLETE_ANSWER);
	}
}
