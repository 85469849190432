import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable} from "mobx";
import type {IGeneralStore} from "data/stores/general/general.store";

export interface IOffSeasonCheckController extends ViewController {
	get isInSeason(): boolean;
}

@injectable()
export class OffSeasonCheckController implements IOffSeasonCheckController {
	constructor(@inject(Bindings.GeneralStore) private _generalStore: IGeneralStore) {
		makeAutoObservable(this);
	}

	get isInSeason(): boolean {
		return this._generalStore.getIsInSeason;
	}

	@action
	init() {
		void this._generalStore.fetchGeneral();
	}

	dispose(): void {
		return;
	}
}
