import {Bindings} from "data/constants/bindings";
import type {ApiResponse, IHttpClientService} from "data/services/http";
import {
	IFetchLeaderboardPayload,
	IGameBarData,
	IGameBarPayload,
	ILeaderboardRanksResponse,
	IShowMyAnswersResponse,
	IShowRivalAnswersPayload,
} from "data/types/entities";
import {inject, injectable} from "inversify";

export type IFetchLeaderboardFunction = (
	payload: IFetchLeaderboardPayload
) => Promise<ApiResponse<ILeaderboardRanksResponse>>;

export interface ILeaderboardApiProvider {
	gameBar: () => Promise<ApiResponse<IGameBarData>>;
	roundGameBar(payload: IGameBarPayload): Promise<ApiResponse<IGameBarData>>;

	fetchRoundLeaderboard: (
		payload: IFetchLeaderboardPayload
	) => Promise<ApiResponse<ILeaderboardRanksResponse>>;

	fetchGroupLeaderboard: (
		payload: IFetchLeaderboardPayload
	) => Promise<ApiResponse<ILeaderboardRanksResponse>>;

	fetchOverallLeaderboard: (
		payload: IFetchLeaderboardPayload
	) => Promise<ApiResponse<ILeaderboardRanksResponse>>;

	fetchRivalPicks(
		payload: IShowRivalAnswersPayload
	): Promise<ApiResponse<IShowMyAnswersResponse>>;
}

@injectable()
export class LeaderboardApiProvider implements ILeaderboardApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	public fetchRoundLeaderboard = (
		payload: IFetchLeaderboardPayload
	): Promise<ApiResponse<ILeaderboardRanksResponse>> => {
		const {group, round, eventId, ...rest} = payload;
		return this._http.get(`ntp/round-rankings/round/${round}/event/${eventId}`, rest);
	};

	public fetchOverallLeaderboard = (
		payload: IFetchLeaderboardPayload
	): Promise<ApiResponse<ILeaderboardRanksResponse>> => {
		const {group, round, eventId, ...rest} = payload;
		return this._http.get(`ntp/overall-rankings/round/${round}/event/${eventId}`, rest);
	};

	public fetchGroupLeaderboard = (
		payload: IFetchLeaderboardPayload
	): Promise<ApiResponse<ILeaderboardRanksResponse>> => {
		const {group, round, eventId, ...rest} = payload;
		return this._http.get(`ntp/rankings/group/${group || 0}`, rest);
	};

	public fetchRivalPicks(
		payload: IShowRivalAnswersPayload
	): Promise<ApiResponse<IShowMyAnswersResponse>> {
		const {eventId, roundId, userId} = payload;
		return this._http.get(`ntp/show_rival/${eventId}/${roundId}/${userId}`);
	}

	public gameBar(): Promise<ApiResponse<IGameBarData>> {
		return this._http.get("ntp/rankings/gamebar");
	}

	public roundGameBar({roundNo, eventId}: IGameBarPayload): Promise<ApiResponse<IGameBarData>> {
		return this._http.get(`ntp/rankings/gamebar/round/${roundNo}/event/${eventId}`);
	}
}
