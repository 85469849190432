import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IGroup} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";
import {GroupStatus} from "data/enums";
import {DateTime} from "luxon";

interface IParams {
	groupId: number;
}

export interface IGroupLabelController extends ViewController<IParams> {
	get group(): IGroup | undefined;

	get isSchedule(): boolean;

	get isLive(): boolean;

	get isComplete(): boolean;

	get scheduleTime(): string;
}

@injectable()
export class GroupLabelController implements IGroupLabelController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;

	constructor(@inject(Bindings.EventsStore) private _eventsStore: IEventsStore) {
		makeAutoObservable(this);
	}

	get isComplete(): boolean {
		return this.group?.status === GroupStatus.Completed;
	}

	get isLive(): boolean {
		return this.group?.status === GroupStatus.Active;
	}

	get isSchedule(): boolean {
		return this.group?.status === GroupStatus.Scheduled;
	}

	get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	get scheduleTime(): string {
		if (!this.group) {
			return "-";
		}
		return DateTime.fromISO(this.group.date).toFormat("hh:mm a");
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._groupId = param.groupId;
	}
}
