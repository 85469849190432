import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalMessage} from "data/types/modals";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface ISuccessModalController extends ViewController {
	get isOpen(): boolean;
	get modalData(): IModalMessage;
	close: () => void;
}

@injectable()
export class SuccessModalController implements ISuccessModalController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	get isOpen(): boolean {
		return this._modalsStore.getIsModalOpen(ModalType.SUCCESS);
	}

	get modalData(): IModalMessage {
		return this._modalsStore.modalContent as IModalMessage;
	}
}
