import {getTieBreakerValues, parseTieBreakerValue} from "data/utils/helpers";
import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Range} from "react-input-range-ios-fix";
import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {ChangeEvent, KeyboardEvent} from "react";

export interface ITieBreakerController extends ViewController {
	setValue: (value: Range | number) => void;
	onSetFeets: (event: ChangeEvent<HTMLInputElement>) => void;
	onSetInches: (event: ChangeEvent<HTMLInputElement>) => void;
	onSetFeetsKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
	onSetInchesKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;

	get value(): number;
	get maxStep(): number;
	get userValue(): string;
	get tieBreakValues(): ReturnType<typeof getTieBreakerValues>;
	get feetInputValue(): string;
	get inchesInputValue(): string;
}

const MAX_FEET = 30;
const INCHES_PER_FEET = 12;

@injectable()
export class TieBreakerController implements ITieBreakerController {
	@observable private _feetInputValue = "";
	@observable private _inchesInputValue = "";
	@observable private _inputsValueObserver$?: ReturnType<typeof reaction>;

	constructor(@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore) {
		makeAutoObservable(this);
	}

	get feetInputValue() {
		return this._feetInputValue ? `${this._feetInputValue}’` : "";
	}

	get inchesInputValue() {
		return this._inchesInputValue ? `${this._inchesInputValue}”` : "";
	}

	get value(): number {
		return this._answerStore.tieBreakerValue;
	}

	get maxStep() {
		return MAX_FEET * INCHES_PER_FEET; // 30 feet, 12 inch per feet
	}

	get userValue() {
		return parseTieBreakerValue(this.value);
	}

	get tieBreakValues() {
		return getTieBreakerValues(this.value);
	}

	dispose(): void {
		this._inputsValueObserver$?.();
	}

	init(): void {
		this._inputsValueObserver$ = reaction(
			() => this.value,
			() => {
				this._feetInputValue = `${this.tieBreakValues.feet}`;
				this._inchesInputValue = `${this.tieBreakValues.inch}`;
			},
			{fireImmediately: true}
		);
	}

	@action
	public setValue = (value: Range | number): void => {
		if (value === undefined || value === null) {
			return;
		}

		this._answerStore.tieBreakerValue = value as number;

		this._feetInputValue = `${this.tieBreakValues.feet}`;
		this._inchesInputValue = `${this.tieBreakValues.inch}`;
	};

	onSetFeetsKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value.replace(/\D/g, "");

		if (event.key === "Backspace") {
			this._feetInputValue = `${value.slice(0, -1)}`;
		}
	};

	onSetFeets = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/\D/g, "");

		if (!value) {
			this._feetInputValue = "";
			return;
		}

		const feets = parseInt(value, 10);

		if (feets >= MAX_FEET) {
			this._answerStore.tieBreakerValue = this.maxStep;
		} else {
			this._answerStore.tieBreakerValue = feets * INCHES_PER_FEET + this.tieBreakValues.inch;
		}

		this._feetInputValue = `${this.tieBreakValues.feet}`;
		this._inchesInputValue = `${this.tieBreakValues.inch}`;
	};

	onSetInchesKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value.replace(/\D/g, "");

		if (event.key === "Backspace") {
			this._inchesInputValue = `${value.slice(0, -1)}`;
		}
	};

	onSetInches = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/\D/g, "");

		if (!value) {
			this._inchesInputValue = "";
			return;
		}

		const inches = parseInt(value, 10);
		const sliderValue = this.tieBreakValues.feet * INCHES_PER_FEET;

		if (this.tieBreakValues.feet === MAX_FEET || inches >= INCHES_PER_FEET) {
			this._answerStore.tieBreakerValue = sliderValue;
		} else {
			this._answerStore.tieBreakerValue = sliderValue + inches;
		}

		this._feetInputValue = `${this.tieBreakValues.feet}`;
		this._inchesInputValue = `${this.tieBreakValues.inch}`;
	};
}
