import {AxiosError} from "axios";
import {IAxiosApiError, IAxiosErrorMessageObject} from "data/types/api";
import {GAME_SEASON, IMAGES_URL, SITE_URL} from "data/constants";
import {Distance, IInchToDistanceMapper} from "data/types/entities";
import {IUser} from "data/stores/user/user.store";

export function getErrorMessageFromAxiosResponse(event: AxiosError<IAxiosApiError, unknown>) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

export function getPlayerImage(playerId: number | undefined): string {
	return `${IMAGES_URL}/players/${playerId || 0}.jpg`;
}

export function getHoleImage(url: string): string {
	return `${SITE_URL}${url}`;
}

const inchFeetMapper: IInchToDistanceMapper[] = [
	{from: 0, to: 60, distance: `0-4'11"`},
	{from: 60, to: 120, distance: `5-9'11"`},
	{from: 120, to: 240, distance: `10-19'11"`},
	{from: 240, to: 480, distance: `20-39'11"`},
	{from: 480, to: Number.MAX_SAFE_INTEGER, distance: `40'+`},
];

export function inchToAnswersDistance(inch: number | null | undefined): Distance {
	if (inch === null || inch === undefined) {
		return null;
	}

	return (
		inchFeetMapper.find((e) => {
			return e.from <= inch && e.to > inch;
		})?.distance || null
	);
}

export function getDashedValue<T>(value: T | null | undefined, replacer = "-"): string | T {
	if (value === null || value === undefined || value === "") {
		return replacer;
	}
	return value;
}

export function getTieBreakerValues(value: number | null | undefined) {
	if (value === undefined || value === null) {
		return {
			feet: 0,
			inch: 0,
		};
	}

	return {
		feet: Math.floor(value / 12),
		inch: value % 12,
	};
}

export function parseTieBreakerValue(value: number | null | undefined): string {
	if (value === undefined || value === null) {
		return "";
	}

	const {feet, inch} = getTieBreakerValues(value);

	if (!feet) {
		return `${inch}”`;
	}

	return `${feet}’${inch}”`;
}

export function trackGAEvent(event: string): void {
	window.dataLayer = window.dataLayer || undefined;
	window.dataLayer?.push({
		event: event,
	});
}

export interface IUserDetails {
	user_id: string;
	user_emid: string;
	user_phid?: string;
}

export interface ITrackingParameters {
	event: string;
	user_details: IUserDetails;
	[key: string]: unknown;
}

export async function digestMessage(message: string) {
	const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
	const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
	const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
	return hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
}

export const trackUser = (params: ITrackingParameters) => {
	trackGAEvent(params.event);
	// window.dataLayer = window.dataLayer || undefined;
	//
	// params.user_details.user_emid = await digestMessage(params.user_details.user_emid);
	// window.dataLayer?.push(params);
};

export function getIsUserRegisteredForGame(
	user: IUser | undefined,
	gameType: string,
	season?: number
): boolean {
	if (!gameType || !user?.registeredGames) {
		return true;
	}

	const game = user.registeredGames.find((e) => e.game === gameType);
	if (!game) {
		return false;
	}

	return Boolean(
		game.seasons.find((e) => {
			return e.season === (season || GAME_SEASON) && e.terms;
		})
	);
}
