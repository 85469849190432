import type {IModalsStore} from "data/stores/modals/modals.store";
import {isEmpty} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {Empty} from "data/types/generics";

export interface IGeneral {
	active: boolean;
	loggedInMessages: [string, string];
	loggedOutMessages: [string, string];
}

export interface IGeneralStore {
	get getIsInSeason(): boolean;
	fetchGeneral(): Promise<void>;
	get loggedInMessages(): [string, string];
	get loggedOutMessages(): [string, string];
}

const DEFAULT_OFFSEASON_LOGGED_IN_TOP_COPY =
	"WILL RETURN SOON.\nWE WILL LET YOU KNOW WHEN YOU CAN START MAKING\nYOUR PICKS.";
const DEFAULT_OFFSEASON_LOGGED_IN_BOTTOM_COPY =
	"IN THE MEANTIME, WHY NOT PLAY\nFANTASY DP WORLD TOUR";
const DEFAULT_OFFSEASON_LOGGED_OUT_TOP_COPY = "WILL RETURN SOON.";
const DEFAULT_OFFSEASON_LOGGED_OUT_BOTTOM_COPY =
	"CREATE YOUR ACCOUNT TODAY TO BE\n THE FIRST TO KNOW WHEN TO MAKE\n YOUR PICKS";
@injectable()
export class GeneralStore implements IGeneralStore {
	@observable private _isLoading: boolean = false;
	@observable private _isOffSeason: boolean = false;

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _settings: Empty<IGeneral>;

	get getIsInSeason(): boolean {
		return this._settings ? this._settings.active : true;
	}

	get loggedOutMessages(): [string, string] {
		return this._settings
			? this._settings.loggedOutMessages
			: [DEFAULT_OFFSEASON_LOGGED_IN_TOP_COPY, DEFAULT_OFFSEASON_LOGGED_IN_BOTTOM_COPY];
	}

	get loggedInMessages(): [string, string] {
		return this._settings
			? this._settings.loggedInMessages
			: [DEFAULT_OFFSEASON_LOGGED_OUT_TOP_COPY, DEFAULT_OFFSEASON_LOGGED_OUT_BOTTOM_COPY];
	}
	@action
	async fetchGeneral() {
		try {
			const {data} = await this._jsonProvider.general();
			if (!data || isEmpty(data) || typeof data !== "object") {
				throw new Error("Error while loading game settings");
			}
			runInAction(() => {
				this._settings = data;
			});
		} catch (e) {
			this._modalsStore.showError({
				message: (e as Error).message,
			});
		}
	}
}
