import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {Distance, IAnswer, ICorrectAnswer, IGroup, IOnlyGroupId} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {GroupHelper} from "data/utils/GroupHelper";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {inchToAnswersDistance} from "data/utils/helpers";

export interface IVoteAccordionDistanceController extends ViewController<IOnlyGroupId> {
	get isLocked(): boolean;

	get answeredClass(): string;

	get incorrectClass(): string;

	get correctClass(): string;

	get isAnswerCorrect(): boolean;

	get isComplete(): boolean;

	get isPointsShouldBeShown(): boolean;

	get correctAnswerValue(): Distance;

	get isCorrectAnswerHidden(): boolean;
}

@injectable()
export class VoteAccordionDistanceController implements IVoteAccordionDistanceController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get isLocked(): boolean {
		return GroupHelper.IS_GROUP_LOCKED(this.group);
	}

	get isComplete(): boolean {
		return GroupHelper.IS_GROUP_COMPLETE(this.group);
	}

	get answeredClass(): string {
		return this.isDistanceAnswered ? "answered" : "";
	}

	get correctClass(): string {
		if (!this.isComplete || !this.isDistanceAnswered) {
			return "";
		}
		return this.isAnswerCorrect ? "correct" : "";
	}

	get isPointsShouldBeShown(): boolean {
		return this.isDistanceAnswered && this.isComplete;
	}

	get incorrectClass(): string {
		if (!this.isComplete || !this.isDistanceAnswered) {
			return "";
		}

		if (this.isPlayerIncorrect) {
			return "incorrect";
		}

		return !this.isAnswerCorrect ? "incorrect" : "";
	}

	get isCorrectAnswerHidden() {
		return this.isAnswerCorrect && !this.isPlayerIncorrect;
	}

	get isAnswerCorrect(): boolean {
		return this.answer?.distance === this.correctAnswerValue;
	}

	get correctAnswerValue(): Distance {
		return inchToAnswersDistance(this.correctAnswer?.distance);
	}

	protected get isDistanceAnswered(): boolean {
		return Boolean(this.answer?.distance);
	}

	protected get isPlayerIncorrect(): boolean {
		if (!this.answer?.player) {
			return false;
		}

		return this.correctAnswer?.player !== this.answer.player;
	}

	protected get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	protected get answer(): IAnswer | undefined {
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	protected get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	dispose(): void {
		return;
	}

	init(param: IOnlyGroupId): void {
		this._groupId = param.groupId;
	}
}
