import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const ForgotPassword = lazy(
	retryFailLoad(() => import("views/pages/forgot_password/forgot_password.page"))
);
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const PredictionsPage = lazy(
	retryFailLoad(() => import("views/pages/predictions/predictions.page"))
);
const SummaryPage = lazy(retryFailLoad(() => import("views/pages/summary/summary.page")));
const LeaderboardPage = lazy(
	retryFailLoad(() => import("views/pages/leaderboard/leaderboard.page"))
);

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<NotAuthOnlyRoute />}>
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
		</Route>

		<Route element={<PrivateRoute />}>
			<Route path="/my-account" element={<MyAccount />} />
			<Route path="/summary" element={<SummaryPage />} />
			<Route path="/leaderboard" element={<LeaderboardPage />} />
		</Route>

		<Route index element={<PredictionsPage />} />
		<Route path="help/*" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default RootRoutes;
