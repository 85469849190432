import {createTheme} from "@mui/material/styles";
import {buttonsTheme} from "assets/theming/buttons.theme";
import {typographyTheme} from "assets/theming/typography.theme";
import {paletteTheme} from "assets/theming/palette.theme";
import {accordionTheme} from "assets/theming/accordion.theme";
import {dividerTheme} from "assets/theming/divider.theme";
import {inputsTheme} from "assets/theming/inputs.theme";
import {tableTheme} from "assets/theming/table.theme";

export const theme = createTheme(
	paletteTheme,
	buttonsTheme,
	accordionTheme,
	dividerTheme,
	inputsTheme,
	tableTheme,
	typographyTheme
);
