import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {
	Distance,
	IAnswer,
	ICorrectAnswer,
	IGroup,
	ISummaryItemParams,
} from "data/types/entities";
import type {ViewController} from "data/types/structure";
import {inchToAnswersDistance} from "data/utils/helpers";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

export interface ISummaryItemDistanceController extends ViewController<ISummaryItemParams> {
	get answerDistance(): Distance;

	get correctAnswerDistance(): Distance;

	get isAnswerIncorrect(): boolean;

	get answerClass(): string;

	get answer(): IAnswer | undefined;
}

@injectable()
export class SummaryItemDistanceController implements ISummaryItemDistanceController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;
	@observable private _localAnswer: IAnswer | undefined;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) private _answerStore: IAnswerStore
	) {
		makeAutoObservable(this);
	}

	get answerDistance(): Distance {
		return this.answer?.distance || null;
	}

	get correctAnswerDistance(): Distance {
		return this.answerDistanceToInch;
	}

	get answer(): IAnswer | undefined {
		if (!this._groupId) {
			return undefined;
		}
		if (this._localAnswer) {
			return this._localAnswer;
		}
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	get isAnswerIncorrect(): boolean {
		if (!this.hasAnswer) {
			return false;
		}

		return this.answerDistanceToInch !== this.answer?.distance;
	}

	protected get answerDistanceToInch(): Distance {
		if (!this.correctAnswer || this.correctAnswer?.distance === null) {
			return null;
		}

		return inchToAnswersDistance(this.correctAnswer.distance);
	}

	get hasAnswer(): boolean {
		return Boolean(this.answer);
	}

	get answerClass(): string {
		if (!this.answer || !this.correctAnswer) {
			return "";
		}

		if (this.isAnswerPlayerIncorrect) {
			return "incorrect";
		}

		return this.answer.distance === this.answerDistanceToInch ? "correct" : "incorrect";
	}

	protected get isAnswerPlayerIncorrect(): boolean {
		if (!this.answer || !this.correctAnswer) {
			return false;
		}
		return this.answer.player !== this.correctAnswer.player;
	}

	protected get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	protected get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	dispose(): void {
		return;
	}

	init(param: ISummaryItemParams): void {
		this._groupId = param.groupId;
		this._localAnswer = param.answer;
	}
}
