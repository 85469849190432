import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {Button, Popover} from "@mui/material";
import styled from "@emotion/styled";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ISharingController} from "views/components/sharing/sharing.controller";
import {useIsMobile} from "data/hooks";

const Wrapper = styled.div`
	position: relative;
`;

const ShareContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	padding: 4px;

	button {
		padding: 4px;
	}

	svg path {
		fill: var(--textColor2);
	}
`;

const PopoverArrow = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(100%, -50%);

	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-left: 8px solid white;
`;

interface IProps {
	popoverClass?: string;
	buttonType?: "pure" | "icon";
	className?: string;
	vertical?: number;
	horizontal?: number;
}

export const Sharing: React.FC<IProps> = observer(
	({buttonType, popoverClass = "", className = "", vertical = 21, horizontal = 4}) => {
		const {isMobileShare} = useIsMobile();
		const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
		const {isPopoverOpen, closePopover, openPopover, shareTwitter, shareNative, shareFacebook} =
			useViewController<ISharingController>(Bindings.SharingController);

		const beforeShare = useCallback(
			(event: React.SyntheticEvent<HTMLButtonElement>) => {
				if (isMobileShare) {
					shareNative();
					return;
				}
				setAnchorEl(event.currentTarget);
				openPopover();
			},
			[openPopover, shareNative, isMobileShare]
		);

		const beforeShareClose = useCallback(() => {
			setAnchorEl(null);
			closePopover();
		}, [closePopover]);

		return (
			<Wrapper>
				<Button className={className} color={buttonType} onClick={beforeShare}>
					<ShareIcon />
				</Button>

				<Popover
					className={`overflow-popover ${popoverClass}`}
					open={isPopoverOpen}
					anchorEl={anchorEl}
					onClose={beforeShareClose}
					anchorOrigin={{
						vertical,
						horizontal,
					}}
					transformOrigin={{
						vertical: "center",
						horizontal: "right",
					}}>
					<ShareContainer>
						<Button color="pure" onClick={shareFacebook}>
							<FacebookIcon />
						</Button>
						<Button color="pure" onClick={shareTwitter}>
							<TwitterIcon />
						</Button>
					</ShareContainer>
					<PopoverArrow />
				</Popover>
			</Wrapper>
		);
	}
);
