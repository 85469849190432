import {GroupStatus} from "data/enums";
import {IGroup} from "data/types/entities";

export class GroupHelper {
	public static IS_GROUP_LOCKED(group: IGroup | undefined): boolean {
		if (!group) {
			return false;
		}
		return [GroupStatus.Completed, GroupStatus.Active].includes(group.status);
	}

	public static IS_GROUP_LIVE(group: IGroup | undefined): boolean {
		if (!group) {
			return false;
		}
		return GroupStatus.Active === group.status;
	}

	public static IS_GROUP_SCHEDULED(group: IGroup | undefined): boolean {
		if (!group) {
			return false;
		}
		return GroupStatus.Scheduled === group.status;
	}

	public static IS_GROUP_COMPLETE(group: IGroup | undefined): boolean {
		if (!group) {
			return false;
		}
		return GroupStatus.Completed === group.status;
	}
}
