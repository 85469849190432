import {type IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGigyaStore} from "data/stores/gigya/gigya.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface ILoginModalController extends ViewController {
	get isOpen(): boolean;
	onClose: () => void;
}

@injectable()
export class LoginModalController implements ILoginModalController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.GigyaStore) private _gigyaStore: IGigyaStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: void): void {
		this._subscriptions$.push(
			reaction(
				() => this._userStore.isAuthorized,
				() => this.checkUser()
			)
		);
	}

	get isOpen(): boolean {
		return this._gigyaStore.isLoginOpen;
	}

	public onClose = (): void => {
		this._gigyaStore.closeLoginModal();
	};

	protected checkUser(): void {
		if (this._userStore.isAuthorized) {
			this.onClose();
		}
	}
}
