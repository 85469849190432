import React from "react";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import {IGroup} from "data/types/entities";

export interface ISliderStepsController extends ViewController {
	handleStepChange: (step: number) => void;
	getStepClass: (step: number) => string;
	goToStep: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	get currentStep(): number;

	get groups(): IGroup[];
}

@injectable()
export class SliderStepsController implements ISliderStepsController {
	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public getStepClass = (step: number): string => {
		let className = "";
		const group = this.groups[step];
		const answer = this._answerStore.getAnswerByGroupId(group?.groupId);

		if (step === this.currentStep) {
			className += "current ";
		}

		if (answer) {
			className += "filled";
		}

		return className;
	};

	public handleStepChange = (step: number): void => {
		this._answerStore.sliderStep = step;
	};

	get currentStep(): number {
		return this._answerStore.sliderStep;
	}

	public goToStep = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		const step = Number(event.currentTarget.dataset.step);
		if (Number.isNaN(step)) {
			return;
		}
		this.handleStepChange(step);
	};

	get groups(): IGroup[] {
		return this._eventsStore?.groups;
	}
}
