/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/Button" {
	// allows to rewrite theme options

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonPropsColorOverrides {
		pure: true;
		icon: true;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonClasses {
		textPure: string;
		textIcon: string;
	}
}

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "var(--fontFamilyBase)",
					padding: "16px 20px",
					height: "44px",
					borderRadius: "22px",
					textTransform: "capitalize",
				},
				containedPrimary: {
					background: "var(--background-blue)",
					color: "var(--text-white)",
					":hover": {
						background: "var(--primary1-75)",
					},
					":disabled": {
						background: "var(--primary1-75)",
						color: "var(--text-white)",
						opacity: 0.3,
					},
				},
				outlinedPrimary: {
					borderColor: "var(--text-dark-blue)",
					color: "var(--text-dark-blue)",
					":hover": {
						color: "var(--primary2-75)",
						borderColor: "var(--primary2-75)",
					},
					":disabled": {
						borderColor: "var(--primary2-75)",
						color: "var(--primary2-75)",
						opacity: 0.3,
					},
					".MuiCircularProgress-svg": {
						color: "var(--text-dark-blue)",
					},
				},
				textPure: {
					padding: 0,
					margin: 0,
					border: "none",
					color: "var(--text-dark-blue)",
					minWidth: 0,
					height: "auto",
					":hover": {
						color: "var(--primary2-75)",
					},
					":disabled": {
						color: "var(--primary2-75)",
						opacity: 0.3,
					},
				},
				textIcon: {
					padding: "4px",
					width: "28px",
					height: "28px",
					margin: 0,
					border: "1px solid #FFFFFF",
					borderRadius: "50%",
					color: "var(--text-white)",
					minWidth: 0,
					svg: {
						width: "18px",
						height: "auto",
					},
					":hover": {
						color: "var(--text-white)",
					},
					":disabled": {
						color: "var(--text-white)",
						opacity: 0.3,
					},

					"&.dark": {
						color: "var(--textColor1)",
						border: "1px solid var(--textColor1)",
					},
				},
			},
		},
	},
};
