import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";

export interface ILeaderboardLoadMoreController extends ViewController {
	loadMoreHandler: () => void;

	get isLoading(): boolean;

	get hasLoadMore(): boolean;
}

@injectable()
export class LeaderboardLoadMoreController implements ILeaderboardLoadMoreController {
	constructor(@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore) {
		makeAutoObservable(this);
	}

	get hasLoadMore(): boolean {
		return this._leaderboardStore.hasLoadMore;
	}

	get isLoading(): boolean {
		return this._leaderboardStore.isLoading;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	loadMoreHandler = (): void => {
		void this._leaderboardStore.loadMore();
	};
}
