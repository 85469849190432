import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

import type {IGigyaStore} from "data/stores/gigya/gigya.store";
import type {IGeneralStore} from "data/stores/general/general.store";

export interface IOffSeasonController extends ViewController {
	get isAuthenticated(): boolean;
	get topCopy(): string;
	get bottomCopy(): string;
	toRegister: () => void;
	toLogin: () => void;
}

@injectable()
export class OffSeasonController implements IOffSeasonController {
	@observable private _requestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GigyaStore) private _gigyaStore: IGigyaStore,
		@inject(Bindings.GeneralStore) private _generalStore: IGeneralStore
	) {
		makeAutoObservable(this);
	}

	get topCopy() {
		return this._userStore.isAuthorized
			? this._generalStore.loggedInMessages?.[0]
			: this._generalStore.loggedOutMessages?.[0];
	}

	get bottomCopy() {
		return this._userStore.isAuthorized
			? this._generalStore.loggedInMessages?.[1]
			: this._generalStore.loggedOutMessages?.[1];
	}
	get isAuthenticated(): boolean {
		return this._userStore.isAuthorized;
	}

	get user() {
		return this._userStore.user!;
	}

	public toRegister = (): void => {
		this._gigyaStore.openRegisterModal();
	};

	public toLogin = (): void => {
		this._gigyaStore.openLoginModal();
	};
	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
