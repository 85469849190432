import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IAuthModalControllerController} from "views/components/modals/authModal/authModal.controller";
import {Bindings} from "data/constants/bindings";
import {CloseButton, Modal as ModalBase, Overlay} from "views/components/modals/common";
import styled from "@emotion/styled";

import {ReactComponent as SuccessRound} from "assets/img/icons/success_round.svg";
import {Button} from "@mui/material";

const Modal = styled(ModalBase)`
	@media (min-width: 441px) {
		//max-width: 100%;
		width: 100%;
	}
`;

const ModalInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 64px 20px 20px 20px;
	gap: 20px;
	text-align: center;

	h2 {
		font-family: var(--fontFamilyBase), sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 100%;
		text-transform: uppercase;
		color: var(--textColor1);
	}

	p {
		font-family: var(--fontFamilyBase), sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		text-transform: capitalize;
		color: var(--textColor1);
	}
`;

const Buttons = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: flex-start;
	align-items: center;
	button {
		width: 100%;
	}
`;

export const AuthModal: React.FC = observer(() => {
	const {isOpen, onClose, toLogin, toRegister} =
		useViewController<IAuthModalControllerController>(Bindings.AuthModalControllerController);

	if (!isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<Overlay onClick={onClose} />
			<Modal className="section-layout">
				<ModalInner>
					<CloseButton onClick={onClose} />

					<SuccessRound />

					<h2>Register / Login</h2>
					<p>To save your answers you will need to register or login.</p>

					<Buttons>
						<Button variant="contained" onClick={toRegister}>
							Register
						</Button>
						<Button variant="outlined" onClick={toLogin}>
							Login
						</Button>
					</Buttons>
				</ModalInner>
			</Modal>
		</React.Fragment>
	);
});
