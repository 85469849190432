import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosApiErrorGeneric} from "data/types/api";
import type {IShowMyAnswersResponse} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";

interface IParams {
	userId: number;
}

export interface ILeaderboardAnswerBlockController extends ViewController<IParams> {
	getSelectedClass: (groupId: number) => string;

	get isLoading(): boolean;

	get userPicks(): IShowMyAnswersResponse | undefined;
}

@injectable()
export class LeaderboardAnswerBlockController implements ILeaderboardAnswerBlockController {
	@observable private _userId: number = Number.MAX_SAFE_INTEGER;
	@observable private _isLoading: boolean = false;
	@observable private _userPicks: IShowMyAnswersResponse | undefined;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get userPicks(): IShowMyAnswersResponse | undefined {
		return this._userPicks;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._userId = param.userId;
		this.fetchUserPicks();
	}

	getSelectedClass = (groupId: number): string => {
		return groupId === this._leaderboardStore.group ? "selected" : "";
	};

	protected fetchUserPicks(): void {
		const eventId = this._eventsStore.nearestEvent?.id;
		if (!this._userId || !eventId) {
			return;
		}

		const payload = {
			userId: this._userId,
			eventId,
		};

		this._isLoading = true;
		this._leaderboardStore
			.fetchUserPicks(payload)
			.then((res) => {
				runInAction(() => {
					this._userPicks = res.data.success;
				});
			})
			.catch((e) => {
				this._modalsStore.showAxiosError(e as AxiosApiErrorGeneric);
			})
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	}
}
