import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {NavigateFunction} from "react-router-dom";
import type {IEventsStore} from "data/stores/events/events.store";

interface IParams {
	navigate: NavigateFunction;
	groupId: number;
}

export interface ISummaryItemNoAnswerController extends ViewController<IParams> {
	goToSliderStep: () => void;
}

@injectable()
export class SummaryItemNoAnswerController implements ISummaryItemNoAnswerController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore
	) {
		makeAutoObservable(this);
	}

	private get groupIndex(): number {
		return this._eventsStore.getGroupIndexById(this._groupId);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
		this._groupId = param.groupId;
	}

	public goToSliderStep = (): void => {
		this._answerStore.clearTieBreaker();
		this._answerStore.sliderStep = this.groupIndex;
		this._navigate?.("/?edit=true");
	};
}
