import styled from "@emotion/styled";
import {Button, Checkbox, FormControl, FormControlLabel, Modal, Stack} from "@mui/material";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import React from "react";
import {Input} from "views/components/form";
import {ModalButtons, ModalContainer, ModalText, ModalTitle} from "views/components/modals/common";
import {ISecondaryRegistrationModalController} from "views/components/modals/secondaryRegistrationModal/secondaryRegistrationModal.controller";

const Form = styled.form`
	a {
		text-decoration: underline;
	}

	.buttons {
		margin-top: 1.25rem;
	}
`;

export const SecondaryRegistrationModal: React.FC = observer(() => {
	const {isOpen, isLoading, onSubmit} = useViewController<ISecondaryRegistrationModalController>(
		Bindings.SecondaryRegistrationModalController
	);
	return (
		<Modal open={isOpen}>
			<ModalContainer>
				<ModalTitle>Welcome to Nearest the Pin</ModalTitle>
				<ModalText>
					Before you enter your picks, please enter a username to appear on leaderboards.{" "}
				</ModalText>

				<Form onSubmit={onSubmit}>
					<FormControl fullWidth>
						<Input
							label="Username"
							name="username"
							type="text"
							placeholder="Username"
							inputProps={{maxLength: 40}}
							sx={{marginBottom: "1.25rem"}}
							required
						/>
					</FormControl>

					<Stack>
						<FormControlLabel
							control={<Checkbox name="opt_in" />}
							label="I would like to receive updates about DP World Tour Nearest the Pin"
						/>

						<FormControlLabel
							control={<Checkbox name="terms" required />}
							label={
								<span>
									<span>I agree to the</span>
									<span> </span>
									<a href="/help/t&c's">Terms & Conditions</a>
								</span>
							}
						/>
					</Stack>

					<ModalButtons className="buttons">
						<Button variant="contained" type="submit" disabled={isLoading}>
							Submit
						</Button>
					</ModalButtons>
				</Form>
			</ModalContainer>
		</Modal>
	);
});
