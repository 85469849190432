import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IGameBarStore} from "data/stores/game_bar/game_bar.store";
import type {IUserStore} from "data/stores/user/user.store";
import {IGameBarData} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	action,
	type IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {noop} from "lodash";

export interface IGameBarController extends ViewController {
	get isLoading(): boolean;

	get isGameBarVisible(): boolean;

	get roundId(): number;

	get gameBarData(): IGameBarData | undefined;
}

@injectable()
export class GameBarController implements IGameBarController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = true;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get roundId(): number {
		return this._eventsStore.roundId;
	}

	get gameBarData() {
		return this._gameBarStore.gameBarData;
	}

	get isGameBarVisible() {
		return this._userStore.isAuthorized && this._userStore.isRegisteredForGame;
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: void): void {
		this.fetchGameBar();

		const subscription = reaction(
			() => [
				this._userStore.isAuthorized,
				this._userStore.isRegisteredForGame,
				this._eventsStore.rounds,
				this._eventsStore.nearestEvent,
			],
			() => this.fetchGameBar()
		);

		this._subscriptions$.push(subscription);
	}

	@action private fetchGameBar(): void {
		if (
			!this._userStore.isAuthorized ||
			!this._userStore.isRegisteredForGame ||
			!this._eventsStore.rounds.length ||
			!this._eventsStore.nearestEvent
		) {
			return;
		}

		this._isLoading = true;

		void this._gameBarStore
			.fetchGameBar()
			.catch(noop)
			.then(() =>
				runInAction(() => {
					this._isLoading = false;
				})
			);
	}
}
