import {Bindings} from "data/constants/bindings";
import {EventStatus, GroupStatus, RoundStatus} from "data/enums";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IEvent, IGameEvent, IGroup, IHoleInfo, IRoundEntity} from "data/types/entities";
import {inject, injectable} from "inversify";
import {isEmpty, last, noop} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface IEventsStore {
	get events(): IEvent[];

	get gameEvent(): IGameEvent | undefined;

	get nearestEvent(): IEvent | undefined;

	get groups(): IGroup[];

	get isSomeGroupIsLocked(): boolean;

	get groupsLength(): number;

	get roundId(): number;

	get roundIds(): number[];

	get holeInfo(): IHoleInfo | undefined;

	get rounds(): IRoundEntity[];

	get settings(): IGameEvent["contestSettings"];

	get selectedRound(): IRoundEntity | undefined;

	fetchEvents(): Promise<void>;

	getGroupIndexById(groupId: number | undefined): number;

	getGroupById(groupId: number | undefined): IGroup | undefined;

	getGroupsByRoundId(roundId: number): IGroup[];

	fetchCurrentEvent(): Promise<void>;

	initializeDataFetch(): void;

	getRoundByRoundNo(roundNo: number): IRoundEntity | undefined;

	selectRoundByRoundNo(roundNo: number): void;

	clearSelectedRound(): void;
}

@injectable()
export class EventsStore implements IEventsStore {
	@observable protected _selectedRound: IRoundEntity | undefined;
	@observable protected _events: IEvent[] = [];
	@observable private _gameEvent: IGameEvent | undefined;
	@observable private _isShowLastComplete: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get events(): IEvent[] {
		return this._events;
	}

	get settings() {
		return this._gameEvent?.contestSettings;
	}

	get gameEvent(): IGameEvent | undefined {
		return this._gameEvent;
	}

	get groups(): IGroup[] {
		return this._gameEvent?.groups?.[this.roundId] || [];
	}

	get isSomeGroupIsLocked(): boolean {
		return this.groups.some((e) =>
			[GroupStatus.Active, GroupStatus.Completed].includes(e.status)
		);
	}

	get groupsLength(): number {
		return this.groups.length;
	}

	get nearestEvent(): IEvent | undefined {
		const active = this.events.find((e) => e.status === EventStatus.Active);
		if (active) {
			return active;
		}

		const schedule = this.events.find((e) => e.status === EventStatus.Scheduled);
		if (schedule) {
			return schedule;
		}

		return this.events.findLast((e) => e.status === EventStatus.Complete);
	}

	get selectedRound() {
		return this._selectedRound || this.currentRound;
	}

	get roundId(): number {
		return this._selectedRound?.roundNo || this.currentRound?.roundNo || 1;
	}

	get currentRound(): IRoundEntity | undefined {
		const playing = this.rounds.find((round) => round.status === RoundStatus.Playing);
		const scheduled = this.rounds.find((round) => round.status === RoundStatus.Scheduled);
		if (playing) {
			return playing;
		}
		if (scheduled) {
			return scheduled;
		}

		return last(this.rounds);
	}

	get roundIds(): number[] {
		return this.rounds.map((round) => round.roundNo);
	}

	get holeInfo(): IHoleInfo | undefined {
		return this._gameEvent?.holes?.[this.roundId];
	}

	get rounds(): IRoundEntity[] {
		return this._gameEvent?.rounds || [];
	}

	protected get allGroups(): IGroup[] {
		return Object.values(this._gameEvent?.groups || {}).flatMap((e) => e);
	}

	public initializeDataFetch(): void {
		this.fetchEvents()
			.then(() => {
				void this.fetchCurrentEvent();
			})
			.catch(noop);
	}

	public getGroupById(groupId: number | undefined) {
		return this.allGroups.find((e) => e.groupId === groupId);
	}

	public getGroupIndexById(groupId: number | undefined) {
		return this.allGroups.findIndex((e) => e.groupId === groupId);
	}

	public getGroupsByRoundId(roundId: number) {
		return this._gameEvent?.groups?.[roundId] || [];
	}

	public async fetchEvents(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.events();
			runInAction(() => {
				this._events = data;
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	getRoundByRoundNo(roundNo: number) {
		return this.rounds.find((e) => e.roundNo === roundNo);
	}

	@action
	selectRoundByRoundNo(roundNo: number) {
		const round = this.rounds.find((e) => e.roundNo === roundNo);
		if (!round) {
			return;
		}
		this._selectedRound = round;
	}

	@action
	clearSelectedRound() {
		this._selectedRound = undefined;
	}

	public async fetchCurrentEvent(): Promise<void> {
		if (!this.nearestEvent) {
			return Promise.reject("no active event found");
		}

		try {
			const {data} = await this._jsonProvider.singleEvent(this.nearestEvent.id);

			if (isEmpty(data.groups)) {
				this._isShowLastComplete = true;
				return;
			}

			runInAction(() => {
				this._gameEvent = data;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
