import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGigyaStore} from "data/stores/gigya/gigya.store";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IMenuController extends ViewController {
	toLogin: () => void;
	toRegister: () => void;
	handleLogout: () => void;
	close: () => void;

	get isMenuOpen(): boolean;
	get isAuthorized(): boolean;
}

@injectable()
export class MenuController implements IMenuController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GigyaStore) private _gigyaStore: IGigyaStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore
	) {
		makeAutoObservable(this);
	}

	get isMenuOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public handleLogout = (): void => {
		window.gigya?.accounts.logout();
		this.close();
	};

	public toLogin = (): void => {
		this._gigyaStore.openLoginModal();
		this.close();
	};

	public toRegister = (): void => {
		this._gigyaStore.openRegisterModal();
		this.close();
	};

	public close = () => {
		this._menuStore.closeMenu();
	};
}
