import React, {Fragment, PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {OffSeasonPage} from "views/pages/off_season/off_season.page";
import type {IOffSeasonCheckController} from "views/components/offseason/off_season_check.controller";

export const OffSeasonCheck: React.FC<PropsWithChildren> = observer(({children}) => {
	const {isInSeason} = useViewController<IOffSeasonCheckController>(
		Bindings.OffSeasonCheckController
	);
	return isInSeason ? <Fragment>{children}</Fragment> : <OffSeasonPage />;
});
