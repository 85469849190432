import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {ITutorialStep} from "data/types/tutorial";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 12px;
`;

const CenterBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	position: relative;
`;

const Step = styled.button`
	width: 16px;
	height: 16px;
	border-radius: 37px;
	background: var(--primary2-50);
	border: none;
	transition: 0.25s;

	&.filled {
		background: var(--textColor2);
	}

	&.current {
		width: calc(16px * 2 + 12px); // 2 steps dots + gap between
		height: 16px;
		border-radius: 37px;
		background: var(--textColor2);
	}

	&.previous {
		background: var(--primary1-10);
	}
`;

interface IProps {
	steps: ITutorialStep[];
	currentStep: number;
	goToStep: (index: number) => void;
}

export const TutorialSteps: React.FC<IProps> = observer(({steps, currentStep, goToStep}) => {
	const handleStepClick = useCallback(
		(event: React.SyntheticEvent<HTMLButtonElement>) => {
			if (!goToStep || typeof goToStep !== "function") {
				return;
			}
			const index = Number(event.currentTarget.dataset.step);
			if (index === null || index === undefined || Number.isNaN(index)) {
				return;
			}

			goToStep(index);
		},
		[goToStep]
	);

	const getStepClass = useCallback(
		(step: number) => {
			if (step < currentStep) {
				return "previous";
			}
			return currentStep === step ? "current" : "";
		},
		[currentStep]
	);

	return (
		<Wrapper>
			<CenterBlock>
				{steps.map((step, index) => (
					<Step
						key={step.title}
						data-step={index}
						className={getStepClass(index)}
						onClick={handleStepClick}
					/>
				))}
			</CenterBlock>
		</Wrapper>
	);
});
