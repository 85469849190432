import React from "react";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {GroupStatus, SummaryPageContent} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ISummaryStore} from "data/stores/summary/summary.store";
import type {IEventsStore} from "data/stores/events/events.store";

export interface ISummaryContentToggleController extends ViewController {
	get isVisible(): boolean;
	getIsSelected: (section: SummaryPageContent) => string;
	onSelect: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

@injectable()
export class SummaryContentToggleController implements ISummaryContentToggleController {
	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.SummaryStore) private _summaryStore: ISummaryStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isVisible(): boolean {
		return this._eventsStore.groups.some((e) => e.status === GroupStatus.Completed);
	}

	public getIsSelected = (section: SummaryPageContent) => {
		return this._summaryStore.summarySelection === section ? "active" : "";
	};

	public onSelect = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
		const selection = event.currentTarget.dataset.selection as SummaryPageContent;
		if (!selection) {
			return;
		}
		this._summaryStore.summarySelection = selection;
	};
}
