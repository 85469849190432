import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {IModalMessage} from "data/types/modals";

export interface IGlobalErrorModalController extends ViewController {
	get isOpen(): boolean;
	get modalData(): IModalMessage;
	close: () => void;
}

@injectable()
export class GlobalErrorModalController implements IGlobalErrorModalController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	get isOpen(): boolean {
		return this._modalsStore.getIsModalOpen(ModalType.ERROR);
	}

	get modalData(): IModalMessage {
		return this._modalsStore.modalContent as IModalMessage;
	}
}
