import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface IMenuStore {
	get isMenuOpen(): boolean;
	toggleMenu(): void;
	closeMenu(): void;
	openMenu(): void;
}

@injectable()
export class MenuStore implements IMenuStore {
	get isMenuOpen(): boolean {
		return this._isMenuOpen;
	}
	@observable private _isMenuOpen: boolean = false;
	constructor() {
		makeAutoObservable(this);
	}

	@action
	closeMenu(): void {
		this._isMenuOpen = false;
	}

	@action
	openMenu(): void {
		this._isMenuOpen = true;
	}

	@action
	toggleMenu(): void {
		this._isMenuOpen = !this.isMenuOpen;
	}
}
