import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IHoleInfo} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";

export interface IHoleInfoController extends ViewController {
	get holeInfo(): undefined | IHoleInfo;
	get roundId(): number;
}

@injectable()
export class HoleInfoController implements IHoleInfoController {
	constructor(@inject(Bindings.EventsStore) private _eventsStore: IEventsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get holeInfo(): IHoleInfo | undefined {
		return this._eventsStore.holeInfo;
	}

	get roundId(): number {
		return this._eventsStore.roundId;
	}
}
