import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {share} from "data/utils";
import {ShareType, SocialNetwork} from "data/enums";

export interface ISharingController extends ViewController {
	openPopover: () => void;
	closePopover: () => void;

	get isPopoverOpen(): boolean;

	shareFacebook: () => void;
	shareTwitter: () => void;
	shareNative: () => void;
}

@injectable()
export class SharingController implements ISharingController {
	@observable private _isPopoverOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	get isPopoverOpen(): boolean {
		return this._isPopoverOpen;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public closePopover = (): void => {
		this._isPopoverOpen = false;
	};

	@action
	public openPopover = (): void => {
		this._isPopoverOpen = true;
	};

	public shareFacebook = (): void => {
		share({
			socialNetwork: SocialNetwork.Facebook,
			type: ShareType.General,
		});
	};

	public shareNative = (): void => {
		share({
			socialNetwork: SocialNetwork.Mobile,
			type: ShareType.General,
			message: "Come and play DP World Tour Nearest The Pin",
		});
	};

	public shareTwitter = (): void => {
		share({
			socialNetwork: SocialNetwork.Twitter,
			type: ShareType.General,
			message: "Come and play DP World Tour Nearest The Pin",
		});
	};
}
