import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IGroup} from "data/types/entities";
import type {ISummaryStore} from "data/stores/summary/summary.store";
import {SummaryPageContent} from "data/enums";

export interface ISummaryPageController extends ViewController {
	get groups(): IGroup[];

	get hasTieBreaker(): boolean;

	get isLoading(): boolean;

	get isPicks(): boolean;

	get isLeaderboard(): boolean;
}

@injectable()
export class SummaryPageController implements ISummaryPageController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading = false;

	constructor(
		@inject(Bindings.SummaryStore) private _summaryStore: ISummaryStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get groups(): IGroup[] {
		return this._eventsStore.groups;
	}

	get hasTieBreaker(): boolean {
		return Boolean(this._answerStore.tieBreakerValue);
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
		this._eventsStore.clearSelectedRound();
	}

	init(param: void): void {
		this.fetchAnswers();

		const subscription$ = reaction(
			() => [
				this._eventsStore.nearestEvent,
				this._eventsStore.roundId,
				this._userStore.isAuthorized,
				this._eventsStore.selectedRound,
			],
			() => this.fetchAnswers()
		);

		this._subscriptions$.push(subscription$);
	}

	@action
	protected fetchAnswers() {
		if (
			!this._userStore.isAuthorized ||
			!this._userStore.isRegisteredForGame ||
			!this._eventsStore.rounds.length
		) {
			return;
		}

		if (this._eventsStore.nearestEvent && this._eventsStore.roundId) {
			this._isLoading = true;
			void this._answerStore.fetchAnswers().finally(() => {
				this._isLoading = false;
			});
		}
	}

	public get isLeaderboard(): boolean {
		return this._summaryStore.summarySelection === SummaryPageContent.Leaderboard;
	}

	public get isPicks(): boolean {
		return this._summaryStore.summarySelection === SummaryPageContent.Picks;
	}
}
