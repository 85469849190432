import {Bindings} from "data/constants/bindings";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import type {ILeaderboardRank} from "data/types/entities";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {find} from "lodash";

export interface ILeaderboardTableController extends ViewController {
	get items(): ILeaderboardRank[];
	get isLoading(): boolean;
	get hasLoadMore(): boolean;
	get userRank(): ILeaderboardRank | null;
	get isUserRankVisible(): boolean;
}

@injectable()
export class LeaderboardTableController implements ILeaderboardTableController {
	constructor(@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isUserRankVisible() {
		const rank = this.userRank;
		return Boolean(rank && !find(this.items, {userId: rank.userId}));
	}

	get userRank() {
		return this._leaderboardStore.userRank;
	}

	get items(): ILeaderboardRank[] {
		return this._leaderboardStore.leaderboardRanks;
	}

	get isLoading(): boolean {
		return this._leaderboardStore.isLoading;
	}

	get hasLoadMore(): boolean {
		return this._leaderboardStore.hasLoadMore;
	}
}
