import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {GroupStatus} from "data/enums";
import {inchToAnswersDistance} from "data/utils/helpers";
import type {Distance, ICorrectAnswer, IGroup, IOnlyGroupId} from "data/types/entities";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IPlayersStore} from "data/stores/players/players.store";

export interface ISummaryItemResultController extends ViewController<IOnlyGroupId> {
	get isLocked(): boolean;

	get isComplete(): boolean;

	get answer(): string;
}

@injectable()
export class SummaryItemResultController implements ISummaryItemResultController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	public get answer(): string {
		if (this.isMissAllCorrect) {
			return "Results: All golfers missed the green";
		}

		if (!this.answerDistanceToInch) {
			return "";
		}
		return `${this.playerName} was nearest to the Pin (${this.answerDistanceToInch})`;
	}

	get isMissAllCorrect(): boolean {
		if (!this.isComplete) {
			return false;
		}

		return this.correctAnswer?.player === null;
	}

	private get playerName(): string {
		const player = this._playersStore.getPlayerById(this.correctAnswer?.player || 0);
		if (!player) {
			return "-";
		}
		return `${player.firstname.charAt(0)} ${player.lastname}`;
	}

	public get isComplete(): boolean {
		if (!this.group) {
			return false;
		}
		return this.group.status === GroupStatus.Completed;
	}

	public get isLocked(): boolean {
		if (!this.group) {
			return false;
		}
		return this.group.status === GroupStatus.Active;
	}

	protected get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	protected get answerDistanceToInch(): Distance {
		if (!this.correctAnswer || this.correctAnswer?.distance === null) {
			return null;
		}

		return inchToAnswersDistance(this.correctAnswer.distance);
	}

	private get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	dispose(): void {
		return;
	}

	init(param: IOnlyGroupId): void {
		this._groupId = param.groupId;
	}
}
