import {Bindings} from "data/constants/bindings";
import {GroupStatus} from "data/enums";
import type {IAnswersApiProvider} from "data/providers/api/answers.api.provider";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosApiErrorGeneric} from "data/types/api";
import type {IAnswer, IFetchAnswersRequest, IShowMyAnswersResponse} from "data/types/entities";
import {inject, injectable} from "inversify";
import {isEqual} from "lodash";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";

export interface IAnswerStore {
	get isSaveAvailable(): boolean;

	get sliderStep(): number;

	set sliderStep(value: number);

	get hasAllAnswers(): boolean;

	get answerLength(): number;

	get savedAnswersLength(): number;

	get isTieBreaker(): boolean;

	get tieBreakerValue(): number;

	set tieBreakerValue(value: number);

	get areAnswersChanged(): boolean;

	get localAnswers(): IAnswer[];

	get isAnswersSaving(): boolean;

	getAnswerByGroupId(groupId: number): IAnswer | undefined;

	getIsAnswerIncompleteByGroupId(groupId: number): boolean;

	saveAnswer(answer: IAnswer): void;

	removeAnswerByGroupId(groupId: number): void;

	nextStep(): void;

	goToTieBreaker(): void;

	clearTieBreaker(): void;

	saveAnswers(): Promise<void>;

	fetchAnswers(): Promise<IShowMyAnswersResponse>;
}

@injectable()
export class AnswerStore implements IAnswerStore {
	@observable private _localAnswers: IAnswer[] = [];
	@observable private _answers: IAnswer[] = [];
	@observable private _isTieBreaker: boolean = false;
	@observable private _sliderStep: number = 0;
	@observable private _tieBreakerValue: number = 0;
	@observable private _isAnswersSaving: boolean = false;

	// Please do not inject answers store to events store to avoid loop injection
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AnswersApiProvider) private _answersApiProvider: IAnswersApiProvider,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get isAnswersSaving(): boolean {
		return this._isAnswersSaving;
	}

	get localAnswers(): IAnswer[] {
		return this._localAnswers;
	}

	get tieBreakerValue(): number {
		return this._tieBreakerValue;
	}

	set tieBreakerValue(value: number) {
		this._tieBreakerValue = value;
	}

	get areAnswersChanged(): boolean {
		const answersObj = this._answers.map(this.getPureAnswer);
		const localAnswersObj = this._localAnswers.map(this.getPureAnswer);
		return !isEqual(answersObj, localAnswersObj);
	}

	get isTieBreaker(): boolean {
		return this._isTieBreaker;
	}

	get sliderStep(): number {
		return this._sliderStep;
	}

	set sliderStep(value: number) {
		this._sliderStep = value;
	}

	get answerLength(): number {
		return this._localAnswers.length;
	}

	get savedAnswersLength(): number {
		return this._answers.length;
	}

	get hasAllAnswers(): boolean {
		return this.answerLength === this._eventsStore.groupsLength;
	}

	get isSaveAvailable(): boolean {
		return this.fullAnswers.length > 0;
	}

	@computed
	protected get fullAnswers(): IAnswer[] {
		return this._localAnswers.filter((e) => !this.getIsAnswerIncompleteByGroupId(e.groupId));
	}

	@computed
	protected get basicAnswerRequestData(): IFetchAnswersRequest {
		return {
			eventId: this._eventsStore.nearestEvent?.id || 0,
			roundId: this._eventsStore.selectedRound?.roundNo || this._eventsStore.roundId,
		};
	}

	@computed
	protected get answersForSave(): IAnswer[] {
		return this._localAnswers
			.filter((e) => !this.getIsAnswerIncompleteByGroupId(e.groupId))
			.filter((e) => this.filterAnswerByStatus(e))
			.map((answer) => ({
				groupId: answer.groupId,
				distance: answer.distance && answer.player ? answer.distance : null,
				player: answer.player || null,
			}));
	}

	protected get tieBreakerForSave(): string {
		return this._tieBreakerValue.toFixed(2);
	}

	public saveAnswer(answer: IAnswer): void {
		const index = this._localAnswers.findIndex((e) => e.groupId === answer.groupId);

		runInAction(() => {
			if (index === -1) {
				this._localAnswers.push(answer);
			} else {
				this._localAnswers[index] = answer;
			}
		});
	}

	public removeAnswerByGroupId(groupId: number): void {
		const index = this._localAnswers.findIndex((e) => e.groupId === groupId);
		if (index !== -1) {
			this._localAnswers.splice(index, 1);
		}
	}

	@action
	public nextStep(): void {
		this._sliderStep += 1;
	}

	@action
	public goToTieBreaker(): void {
		this._isTieBreaker = true;
	}

	@action
	public clearTieBreaker(): void {
		this._isTieBreaker = false;
	}

	@action
	public async saveAnswers(): Promise<void> {
		if (!this._eventsStore.nearestEvent) {
			return Promise.reject("No event to save answers");
		}

		try {
			this._isAnswersSaving = true;
			const payload = {
				answers: this.answersForSave,
				tieBreaker: this.tieBreakerForSave,
			};

			const {data} = await this._answersApiProvider.saveAnswers(payload);
			runInAction(() => {
				this._localAnswers = data.success.answers;
				this._answers = data.success.answers;
				this._tieBreakerValue = Number(data.success.tieBreaker) || 0;
			});
		} catch (e) {
			this._modalsStore.showAxiosError(e as AxiosApiErrorGeneric);
			return Promise.reject(e);
		} finally {
			this._isAnswersSaving = false;
		}
	}

	public async fetchAnswers(): Promise<IShowMyAnswersResponse> {
		try {
			const {data} = await this._answersApiProvider.getAnswers(this.basicAnswerRequestData);

			runInAction(() => {
				if (!this.areAnswersChanged) {
					this._localAnswers = data.success.answers;
				}
				this._answers = data.success.answers;
				this._tieBreakerValue = Number(data.success.tieBreaker) || 0;
			});
			return Promise.resolve(data.success);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public getAnswerByGroupId(groupId: number): IAnswer | undefined {
		return this._localAnswers.find((e) => e.groupId === groupId);
	}

	public getIsAnswerIncompleteByGroupId(groupId: number): boolean {
		const answer = this.getAnswerByGroupId(groupId);
		if (!answer) {
			return false;
		}
		const {player, distance} = answer;
		return player !== 0 && !distance;
	}

	protected getPureAnswer = (answer: IAnswer): IAnswer => ({
		distance: answer.distance || null,
		player: answer.player || null,
		groupId: answer.groupId,
	});

	protected filterAnswerByStatus(answer: IAnswer): boolean {
		const group = this._eventsStore.getGroupById(answer.groupId);
		if (!group) {
			return true;
		}
		return group.status === GroupStatus.Scheduled;
	}
}
