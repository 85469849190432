import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {LoginModal} from "views/components/modals/loginModal/loginModal.component";
import {RegisterModal} from "views/components/modals/registerModal/registerModal.component";
import {AuthModal} from "views/components/modals/authModal/authModal.component";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {GlobalErrorModal} from "views/components/modals/globalErrorModal/globalErrorModal.component";
import styled from "@emotion/styled";
import {SecondaryRegistrationModal} from "views/components/modals/secondaryRegistrationModal/secondaryRegistrationModal.component";
import {TermsModal} from "views/components/modals/termsModal/termsModal.component";
import {SuccessModalComponent} from "views/components/modals/successModal/successModal.component";

const AppContainer = styled.div`
	width: 100%;
	max-width: 420px;
	position: relative;
	margin: 0 auto;
	flex-flow: column;
	-webkit-box-pack: unset;
	display: flex;
	flex: 1 1 0;
	align-items: flex-start;
	justify-content: stretch;
	overflow-x: hidden;
	overflow-y: auto;
	background: #fafaff;
	min-height: 100vh;
`;

export const HOCMain: React.FC<PropsWithChildren> = observer(({children}) => {
	useViewController(Bindings.HOCMainController, undefined);
	return (
		<AppContainer>
			<React.Fragment>{children}</React.Fragment>
			<TermsModal />
			<AuthModal />
			<SecondaryRegistrationModal />
			<LoginModal />
			<RegisterModal />
			<GlobalErrorModal />
			<SuccessModalComponent />
		</AppContainer>
	);
});
