import styled from "@emotion/styled";
import {Button} from "@mui/material";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {IMenuController} from "views/components/menu/menu.controller";
import {Sharing} from "views/components/sharing/sharing.component";

const Wrapper = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	top: 56px;
	right: 0;
	transform: translateX(100%);
	background: #ffffff;
	z-index: 100;
	transition: 0.5s;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 36px;
	padding: 28px 20px;

	&.open {
		transform: translateX(0);
	}

	a {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 100%;

		display: flex;
		align-items: center;
		text-align: center;
		text-transform: uppercase;
		color: var(--primary1);
	}
`;

const BottomBlock = styled.div`
	margin-top: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	width: 100%;

	.share-icon {
		margin-bottom: 12px;
		border-color: rgba(69, 65, 117, 1);

		svg,
		&:hover svg,
		&:hover {
			color: rgba(69, 65, 117, 1);
		}

		svg {
			margin-right: 1px;
		}

		&:hover {
			border-color: rgba(69, 65, 117, 1);
		}
	}
`;

export const MenuComponent: React.FC = observer(() => {
	const location = useLocation();

	const {toLogin, toRegister, handleLogout, close, isMenuOpen, isAuthorized} =
		useViewController<IMenuController>(Bindings.MenuController);

	useEffect(() => {
		close();
	}, [location.pathname, close]);

	const className = isMenuOpen ? "open" : "";
	return (
		<Wrapper className={className}>
			<NavLink to="/">Picks</NavLink>
			<NavLink to="/help/prizes">Prizes</NavLink>
			<NavLink to="/help">Help</NavLink>

			<Exist when={isAuthorized}>
				<NavLink to="/my-account">My Account</NavLink>
			</Exist>

			<BottomBlock>
				<Sharing buttonType="icon" className="share-icon" horizontal={-10} vertical={10} />

				<Exist when={!isAuthorized}>
					<Button fullWidth variant="contained" onClick={toRegister}>
						Register
					</Button>
					<Button fullWidth variant="outlined" onClick={toLogin}>
						Login
					</Button>
				</Exist>

				<Exist when={isAuthorized}>
					<Button onClick={handleLogout} variant="contained" fullWidth>
						Log Out
					</Button>
				</Exist>
			</BottomBlock>
		</Wrapper>
	);
});
