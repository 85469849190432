import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import {IPropsWithNavigate} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {parseTieBreakerValue} from "data/utils/helpers";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import type {NavigateFunction} from "react-router-dom";

export interface ISummaryTieBreakerController extends ViewController<IPropsWithNavigate> {
	goToTieBreaker: () => void;

	get hasTieBreaker(): boolean;

	get tieBreakerValue(): string;

	get isTieBreakerLocked(): boolean;

	get isTieBreakerCorrect(): boolean;

	get isTieBreakerIncorrect(): boolean;
}

@injectable()
export class SummaryTieBreakerController implements ISummaryTieBreakerController {
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore
	) {
		makeAutoObservable(this);
	}

	get hasTieBreaker(): boolean {
		return Boolean(this._answerStore.tieBreakerValue);
	}

	get tieBreakerValue(): string {
		return parseTieBreakerValue(this._answerStore.tieBreakerValue);
	}

	get isTieBreakerLocked(): boolean {
		return this._eventsStore.isSomeGroupIsLocked;
	}

	dispose(): void {
		return;
	}

	init(param: IPropsWithNavigate): void {
		this._navigate = param.navigate;
	}

	public goToTieBreaker = () => {
		this._answerStore.goToTieBreaker();
		this._navigate?.("/?edit=true");
	};

	public get isTieBreakerCorrect(): boolean {
		return false;
	}

	public get isTieBreakerIncorrect(): boolean {
		return false;
	}
}
