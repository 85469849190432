export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum EventStatus {
	Complete = "complete",
	Cancelled = "cancelled",
	Active = "playing",
	Scheduled = "scheduled",
}

export enum GroupStatus {
	Scheduled = "scheduled",
	Active = "active",
	Completed = "completed",
}

export enum ModalType {
	ERROR,
	SUCCESS,
	NOT_ALL_ANSWERS,
	INCOMPLETE_ANSWER,
	GROUP_INFO,
	TERMS,
	SECONDARY_REGISTRATION,
}

export enum SummaryPageContent {
	Picks = "picks",
	Leaderboard = "leaderboard",
}
