import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ITutorialStep} from "data/types/tutorial";
import step1Image from "assets/img/tutorial/step-1.jpg";
import step2Image from "assets/img/tutorial/step-2.jpg";
import step3Image from "assets/img/tutorial/step-3.jpg";
import step4Image from "assets/img/tutorial/step-4.jpg";
import step5Image from "assets/img/tutorial/step-5.jpg";

export interface ITutorialStore {
	get steps(): ITutorialStep[];

	get isTutorialOpen(): boolean;

	openTutorialModal(): void;

	getIsTutorialViewedStorage(): boolean;

	closeTutorialModal(): void;
}

const STORAGE_KEY = "ntp_tutorial";

@injectable()
export class TutorialStore implements ITutorialStore {
	@observable private _isTutorialOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	public get isTutorialOpen(): boolean {
		return this._isTutorialOpen;
	}

	public get steps(): ITutorialStep[] {
		return [
			{
				title: "SELECT YOUR GOLFERS",
				text: "Predict which golfers will land nearest to the pin in each of the in game groups",
				image: step1Image,
			},
			{
				title: "PREDICT THEIR DISTANCE",
				text: "Select a distance band for how far you think the golfer will land from the pin",
				image: step2Image,
			},
			{
				title: "GET CLOSE TO THE ACTION",
				text: "Keep an eye on your predictions throughout the day to see how many you get correct",
				image: step3Image,
			},
			{
				title: "WIN AMAZING PRIZES",
				text: "Select your picks and be automatically entered into our prize draw to win amazing prizes each day",
				image: step4Image,
			},
			{
				title: "MAKE YOUR PICKS",
				text: "Click the button below to start making your picks now!",
				image: step5Image,
			},
		];
	}

	public closeTutorialModal(): void {
		localStorage.setItem(STORAGE_KEY, "true");
		this._isTutorialOpen = false;
	}

	public openTutorialModal(): void {
		this._isTutorialOpen = true;
	}

	public getIsTutorialViewedStorage(): boolean {
		return JSON.parse(localStorage.getItem(STORAGE_KEY) || "false") as boolean;
	}
}
