import {ThemeOptions} from "@mui/material/styles/createTheme";

const focusedClass = ".Mui-focused";
const invalidColor = "var(--action-invalid)";
const validColor = "var(--action-valid)";

export const inputsTheme: ThemeOptions = {
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					input: {
						fontWeight: "500",
						"&[type=number]": {
							"-moz-appearance": "textfield",
						},
						"&::-webkit-outer-spin-button": {
							"-webkit-appearance": "none",
							margin: 0,
						},
						"&::-webkit-inner-spin-button": {
							"-webkit-appearance": "none",
							margin: 0,
						},
					},
					fieldset: {
						borderWidth: "2px",
						borderRadius: "4px",
					},
					[`${focusedClass} fieldset.MuiOutlinedInput-notchedOutline`]: {
						// borderWidth: "2px"
					},
					[`label${focusedClass}`]: {
						color: "#000000",
					},
					".MuiFormHelperText-root": {
						marginLeft: 0,
						textTransform: "inherit",
					},
					".Mui-error input": {
						// borderColor: colorsTheme.color.components.input.error,
					},
					".Mui-disabled": {
						opacity: "0.5",
					},
					"&.answered.incorrect": {
						svg: {
							display: "none",
						},
						".MuiInputBase-root": {
							opacity: 1,
							background: "#F6E6E7",
						},
						label: {
							color: invalidColor,
							opacity: 1,
						},
						fieldset: {
							borderColor: invalidColor,
						},
						".MuiSelect-outlined": {
							color: invalidColor,
							"-webkit-text-fill-color": invalidColor,
						},
					},
					"&.answered.correct": {
						svg: {
							display: "none",
						},
						".MuiInputBase-root": {
							opacity: 1,
							background: "#E5F2E6",
						},
						label: {
							color: validColor,
							opacity: 1,
						},
						fieldset: {
							borderColor: validColor,
						},
						".MuiSelect-outlined": {
							color: validColor,
							"-webkit-text-fill-color": validColor,
						},
					},
				},
			},
		},
	},
};
