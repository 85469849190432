import {Bindings} from "data/constants/bindings";
import type {
	ILeaderboardApiProvider,
	IFetchLeaderboardFunction,
} from "data/providers/api/leaderboard.api.provider";
import type {IEventsStore} from "data/stores/events/events.store";
import type {
	IFetchLeaderboardPayload,
	ILeaderboardRank,
	IShowRivalAnswersPayload,
	IShowRivalAnswersStorePayload,
} from "data/types/entities";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface ILeaderboardStore {
	get isLoading(): boolean;

	get round(): number;

	set round(value: number);

	get group(): number;

	set group(value: number);

	get hasLoadMore(): boolean;

	get userRank(): ILeaderboardRank | null;

	get leaderboardRanks(): ILeaderboardRank[];

	fetchLeaderboard(clear?: boolean): Promise<void>;

	fetchUserPicks(
		payload: IShowRivalAnswersStorePayload
	): ReturnType<ILeaderboardApiProvider["fetchRivalPicks"]>;

	loadMore(): Promise<void>;
}

@injectable()
export class LeaderboardStore implements ILeaderboardStore {
	@observable private _isLoading: boolean = false;
	@observable private _page: number = 1;
	@observable private _round: number = 0;
	@observable private _group: number = 0;
	@observable private _userRank: ILeaderboardRank | null = null;
	@observable private _hasLoadMore: boolean = false;
	@observable private _leaderboardRanks: ILeaderboardRank[] = [];

	constructor(
		@inject(Bindings.LeaderboardApiProvider)
		private _leaderboardApiProvider: ILeaderboardApiProvider,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get leaderboardRanks(): ILeaderboardRank[] {
		return this._leaderboardRanks;
	}

	get hasLoadMore(): boolean {
		return this._hasLoadMore;
	}

	get group(): number {
		return this._group;
	}

	set group(value: number) {
		this._group = value;
	}

	get userRank() {
		return this._userRank;
	}

	get round(): number {
		return this._round;
	}

	set round(value: number) {
		this._round = value;
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	protected get requestPayload(): IFetchLeaderboardPayload {
		return {
			round: this._round || this._eventsStore.roundId,
			eventId: this._eventsStore.nearestEvent?.id || 0,
			group: this._group || undefined,
			page: this._page,
			limit: 10,
		};
	}

	@action
	public async fetchLeaderboard(clear = true): Promise<void> {
		if (clear) this._page = 1;

		try {
			this._isLoading = true;
			const request = this.getRequestAccordingParams();
			const {data} = await request(this.requestPayload);

			runInAction(() => {
				this.concatLeaderboards(data.success.ranking);
				this._hasLoadMore = Boolean(data.success.nextPage);
				this._userRank = data.success.userRank;
			});

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		} finally {
			this._isLoading = false;
		}
	}

	public fetchUserPicks(
		params: IShowRivalAnswersStorePayload
	): ReturnType<ILeaderboardApiProvider["fetchRivalPicks"]> {
		const payload: IShowRivalAnswersPayload = {
			...params,
			roundId: this._round,
		};
		return this._leaderboardApiProvider.fetchRivalPicks(payload);
	}

	@action
	public loadMore(): Promise<void> {
		this._page += 1;
		return this.fetchLeaderboard(false);
	}

	protected getRequestAccordingParams = (): IFetchLeaderboardFunction => {
		if (this.group) {
			return this._leaderboardApiProvider.fetchGroupLeaderboard;
		}

		if (this.round) {
			return this._leaderboardApiProvider.fetchRoundLeaderboard;
		}

		return this._leaderboardApiProvider.fetchOverallLeaderboard;
	};

	@action
	protected concatLeaderboards(rankings: ILeaderboardRank[]): void {
		if (this._page === 1) {
			this._leaderboardRanks = rankings;
			return;
		}
		this._leaderboardRanks = this.leaderboardRanks.concat(rankings);
	}
}
