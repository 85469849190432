import React, {Fragment, PropsWithChildren, useCallback, useEffect} from "react";
import {PagePreloader} from "views/components/preloader";
import {useViewController} from "data/services/locator";
import {IGigyaGateController} from "views/components/gigyaGate/gigyaGate.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";

export const GigyaGate: React.FC<PropsWithChildren> = observer(({children}) => {
	const {isChildrenVisible, onLogout, setUserLoginPayload} =
		useViewController<IGigyaGateController>(Bindings.GigyaGateController);

	const onLogin = useCallback(() => {
		// if (!IS_SECRET_PASSED) return;

		window.gigya?.accounts.getAccountInfo({
			include: "id_token,profile,data",
			callback: (resp) => {
				if (resp.errorCode) {
					return;
				}

				const {
					UID: gigya_id,
					UIDSignature: uid_signature,
					signatureTimestamp: signature_timestamp,
				} = resp;

				setUserLoginPayload({
					uid: gigya_id,
					uidSignature: uid_signature,
					signatureTimestamp: signature_timestamp,
				});
			},
		});
	}, [setUserLoginPayload]);

	useEffect(() => {
		onLogin();

		window.gigya?.accounts.addEventHandlers({
			onLogin,
			onLogout,
		});
	}, [onLogin, onLogout]);

	if (!isChildrenVisible) {
		return <PagePreloader />;
	}

	return <Fragment>{children}</Fragment>;
});
