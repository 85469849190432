import {inject, injectable} from "inversify";
import type {ApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {
	IRoundWatchFetchPayload,
	IRoundWatchFetchResponse,
	IRoundWatchSavePayload,
} from "data/types/round-watch";

export interface IRoundWatchApiProvider {
	fetchRoundWatch(
		payload: IRoundWatchFetchPayload
	): Promise<ApiResponse<IRoundWatchFetchResponse>>;
	saveRoundWatch(payload: IRoundWatchSavePayload): Promise<ApiResponse<IRoundWatchFetchResponse>>;
}

@injectable()
export class RoundWatchApiProvider implements IRoundWatchApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	fetchRoundWatch({
		eventId,
	}: IRoundWatchFetchPayload): Promise<ApiResponse<IRoundWatchFetchResponse>> {
		return this._http.get(`ntp/live_watching/${eventId}`);
	}

	saveRoundWatch({
		watching,
		eventId,
		roundNo,
	}: IRoundWatchSavePayload): Promise<ApiResponse<IRoundWatchFetchResponse>> {
		return this._http.post(`ntp/live_watching/${eventId}/${roundNo}`, {watching});
	}
}
