import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {GIGYA_SCREENSET} from "data/constants";
import {IRegisterModalController} from "views/components/modals/registerModal/registerModal.controller";
import {Bindings} from "data/constants/bindings";
import {
	CloseButton,
	ModalContent,
	ModalInner,
	Modal as ModalBase,
	Overlay,
} from "views/components/modals/common";
import styled from "@emotion/styled";

const Modal = styled(ModalBase)`
	@media (min-width: 441px) {
		//max-width: 100%;
		width: 100%;
	}
`;

const GigyaModalContent = styled(ModalContent)`
	text-align: left;

	h2 {
		margin-block-start: 0.83em;
		margin-block-end: 0.83em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1.3333;

		@media only screen and (min-width: 48rem) {
			h2 {
				font-size: 2rem;
				line-height: 1.25;
			}
		}
		@media only screen and (min-width: 62rem) {
			h2 {
				font-size: 2.5rem;
				line-height: 1.2;
			}
		}
		@media only screen and (min-width: 78.75rem) {
			h2 {
				font-size: 3rem;
				line-height: 1.1667;
			}
		}
	}

	select {
		appearance: none;
	}

	input {
		border: 0;
		background: none;
	}
`;

export const RegisterModal: React.FC = observer(() => {
	const {isOpen, onClose} = useViewController<IRegisterModalController>(
		Bindings.RegisterModalController
	);

	useEffect(() => {
		if (!isOpen) {
			return;
		}
		window.gigya?.accounts.showScreenSet({
			screenSet: GIGYA_SCREENSET,
			containerID: "registration-modal",
			startScreen: "gigya-register-screen",
		});
	}, [isOpen]);

	if (!isOpen) {
		return null;
	}

	return (
		<React.Fragment>
			<Overlay onClick={onClose} />
			<Modal className="section-layout">
				<ModalInner className="user--registration-modal">
					<CloseButton onClick={onClose} />
					<GigyaModalContent
						id="registration-modal"
						className="user--modal-contents user--modal-nest"
					/>
				</ModalInner>
			</Modal>
		</React.Fragment>
	);
});
