export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	AnswersApiProvider: Symbol("AnswersApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	LeaderboardApiProvider: Symbol("LeaderboardApiProvider"),
	RoundWatchApiProvider: Symbol("RoundWatchApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	GeneralStore: Symbol("GeneralStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	GigyaStore: Symbol("GigyaStore"),
	AnswerStore: Symbol("AnswerStore"),
	MenuStore: Symbol("MenuStore"),
	EventsStore: Symbol("EventsStore"),
	ChecksumStore: Symbol("ChecksumStore"),
	ModalsStore: Symbol("ModalsStore"),
	LeaderboardStore: Symbol("LeaderboardStore"),
	GameBarStore: Symbol("GameBarStore"),
	TutorialStore: Symbol("TutorialStore"),
	LiveWatchingStore: Symbol("LiveWatchingStore"),
	SummaryStore: Symbol("SummaryStore"),
	AuthController: Symbol("AuthController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	OffSeasonController: Symbol("OffSeasonController"),
	OffSeasonCheckController: Symbol("OffSeasonCheckController"),
	BootstrapController: Symbol("BootstrapController"),
	LoginModalController: Symbol("LoginModalController"),
	RegisterModalController: Symbol("RegisterModalController"),
	AuthModalControllerController: Symbol("AuthModalControllerController"),
	GigyaGateController: Symbol("GigyaGateController"),
	TieBreakerController: Symbol("TieBreakerController"),
	SliderStepsController: Symbol("SliderStepsController"),
	ContestSliderController: Symbol("ContestSliderController"),
	ContestSheetController: Symbol("ContestSheetController"),
	HeaderController: Symbol("HeaderController"),
	MenuController: Symbol("MenuController"),
	VoteAccordionController: Symbol("VoteAccordionController"),
	HOCMainController: Symbol("HOCMainController"),
	PredictionsController: Symbol("PredictionsController"),
	GlobalErrorModalController: Symbol("GlobalErrorModalController"),
	NotAllAnswersModalController: Symbol("NotAllAnswersModalController"),
	LeaveModalController: Symbol("LeaveModalController"),
	IncompleteAnswerModalController: Symbol("IncompleteAnswerModalController"),
	SummaryPageController: Symbol("SummaryPageController"),
	SummaryItemController: Symbol("SummaryItemController"),
	SummaryTieBreakerController: Symbol("SummaryTieBreakerController"),
	GroupInfoModalController: Symbol("GroupInfoModalController"),
	TermsModalController: Symbol("TermsModalController"),
	LeaderboardTableRowController: Symbol("LeaderboardTableRowController"),
	LeaderboardFiltersController: Symbol("LeaderboardFiltersController"),
	LeaderboardLoadMoreController: Symbol("LeaderboardLoadMoreController"),
	SharingController: Symbol("SharingController"),
	HoleInfoController: Symbol("HoleInfoController"),
	AnswerButtonController: Symbol("AnswerButtonController"),
	GroupLabelController: Symbol("GroupLabelController"),
	SummaryItemAnswerController: Symbol("SummaryItemAnswerController"),
	SummaryItemDistanceController: Symbol("SummaryItemDistanceController"),
	LeaderboardAnswerBlockController: Symbol("LeaderboardAnswerBlockController"),
	SecondaryRegistrationModalController: Symbol("SecondaryRegistrationModalController"),
	LeaderboardTableController: Symbol("LeaderboardTableController"),
	GameBarController: Symbol("GameBarController"),
	VoteAccordionDistanceController: Symbol("VoteAccordionDistanceController"),
	AccordionPillController: Symbol("AccordionPillController"),
	TutorialModalController: Symbol("TutorialModalController"),
	RoundPlayModalController: Symbol("RoundPlayModalController"),
	SuccessModalController: Symbol("SuccessModalController"),
	SummaryItemNoAnswerController: Symbol("SummaryItemNoAnswerController"),
	SummaryItemEditController: Symbol("SummaryItemEditController"),
	SummaryItemPlayerAnswerController: Symbol("SummaryItemPlayerAnswerController"),
	SummaryItemMissAllAnswerController: Symbol("SummaryItemMissAllAnswerController"),
	SummaryItemResultController: Symbol("SummaryItemResultController"),
	SummaryRoundSelectorController: Symbol("SummaryRoundSelectorController"),
	SummaryContentToggleController: Symbol("SummaryContentToggleController"),
};
