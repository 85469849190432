import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import type {IUserLoginPayload} from "data/types/user";

export interface IGigyaStore {
	get userLoginPayload(): IUserLoginPayload | undefined;

	set userLoginPayload(value: IUserLoginPayload | undefined);

	get isLoginOpen(): boolean;

	get isRegisterOpen(): boolean;

	get isAuthModalOpen(): boolean;

	openLoginModal(): void;

	closeLoginModal(): void;

	openRegisterModal(): void;

	closeRegisterModal(): void;

	openAuthModal(): void;

	closeAuthModal(): void;
}

@injectable()
export class GigyaStore implements IGigyaStore {
	@observable private _userLoginPayload: IUserLoginPayload | undefined;
	@observable private _isLoginOpen: boolean = false;
	@observable private _isRegisterOpen: boolean = false;
	@observable private _isAuthModalOpen: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	get userLoginPayload(): IUserLoginPayload | undefined {
		return this._userLoginPayload;
	}

	set userLoginPayload(value: IUserLoginPayload | undefined) {
		this._userLoginPayload = value;
	}

	get isLoginOpen(): boolean {
		return this._isLoginOpen;
	}

	get isRegisterOpen(): boolean {
		return this._isRegisterOpen;
	}

	get isAuthModalOpen(): boolean {
		return this._isAuthModalOpen;
	}

	set isAuthModalOpen(value: boolean) {
		this._isAuthModalOpen = value;
	}

	@action
	public openLoginModal(): void {
		this._isLoginOpen = true;
	}

	@action
	public closeLoginModal(): void {
		this._isLoginOpen = false;
	}

	@action
	public openRegisterModal(): void {
		this._isRegisterOpen = true;
	}

	@action
	public closeRegisterModal(): void {
		this._isRegisterOpen = false;
	}

	@action
	openAuthModal(): void {
		this._isAuthModalOpen = true;
	}

	@action
	closeAuthModal(): void {
		this._isAuthModalOpen = false;
	}
}
