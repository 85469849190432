import React, {Fragment} from "react";
import styled from "@emotion/styled";
import backgroundImage from "assets/img/background_offseason.png";
import logo from "assets/img/logo_stack.svg";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IOffSeasonController} from "views/pages/off_season/off_season.controller";
import {Exist} from "views/components/exist/exist.component";
import {Button} from "@mui/material";
import {Header} from "views/components/header/header.component";

const Content = styled.div`
	width: 100%;
	height: 100%;
	min-height: 100vh;
	display: flex;
	background: url("${backgroundImage}") center/cover no-repeat;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 52px 20px 0 20px;
	img {
		max-width: 180px;
		width: 100%;
	}
`;
const TopDescriptionCopy = styled.div`
	margin-top: 20px;
	color: #fff;
	text-align: center;
	font-feature-settings: "clig" off, "liga" off;
	font-family: var(--fontFamilyBase);
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	text-transform: uppercase;
	> div {
		white-space: pre-wrap;
	}
`;

const BottomDescriptionCopy = styled.div`
	font-family: var(--fontFamilyBase);
	text-align: center;
	font-feature-settings: "clig" off, "liga" off;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	color: #fff;
	text-transform: uppercase;
	margin-top: 16px;
	> div {
		white-space: pre-wrap;
	}
`;
const Description = styled.div`
	font-size: 28px;
	font-weight: 700;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 20px;
	}
`;
const Buttons = styled.div`
	margin-top: 28px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 12px;
	align-items: center;

	button {
		text-transform: capitalize;
		width: 100%;
	}
`;

const LoginButton = styled(Button)`
	color: #070247;
	background-color: white;

	&:hover {
		color: #dcdcdc;
	}
`;

export const OffSeasonPage: React.FC = observer(() => {
	const {isAuthenticated, toLogin, toRegister, topCopy, bottomCopy} =
		useViewController<IOffSeasonController>(Bindings.OffSeasonController);

	return (
		<Fragment>
			<Header />
			<Content>
				<Description>
					<img src={logo} alt="Star" />
					<TopDescriptionCopy>
						{/*Disabled due html come from our BE and must be safety*/}
						{/*eslint-disable-next-line*/}
						<div dangerouslySetInnerHTML={{__html: topCopy}} />
					</TopDescriptionCopy>
					<BottomDescriptionCopy>
						{/*Disabled due html come from our BE and must be safety*/}
						{/*eslint-disable-next-line*/}
						<div dangerouslySetInnerHTML={{__html: bottomCopy}} />
					</BottomDescriptionCopy>
				</Description>
				<Exist when={isAuthenticated}>
					<Buttons>
						<Button variant="contained">
							<a
								href="https://fantasy.dpworldtour.com/"
								target="_blank"
								rel="noreferrer">
								Play Fantasy
							</a>
						</Button>
					</Buttons>
				</Exist>
				<Exist when={!isAuthenticated}>
					<Buttons>
						<LoginButton variant="contained" onClick={toRegister}>
							Sign Up
						</LoginButton>
						<Button variant="contained" onClick={toLogin}>
							Login
						</Button>
					</Buttons>
				</Exist>
			</Content>
		</Fragment>
	);
});
export default OffSeasonPage;
