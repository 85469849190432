import {ThemeOptions} from "@mui/material/styles/createTheme";

const bgBlue = "var(--background-blue)";
export const tableTheme: ThemeOptions = {
	components: {
		MuiTableHead: {
			styleOverrides: {
				root: {
					"th, td": {
						background: bgBlue,
						color: "#FFFFFF",
						paddingTop: "12px",
						paddingBottom: "12px",
						borderColor: bgBlue,
					},
				},
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					"th, td": {
						fontSize: "16px",
						fontWeight: 700,
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					transition: ".25s",
					borderBottom: "1px solid var(--border-primary)",
					"&.collapse-row": {
						border: "none",
						"&.open": {
							borderBottom: "1px solid var(--border-primary)",
						},
					},
					"&.active": {
						"th, td": {
							background: bgBlue,
							color: "#FFFFFF",
							button: {
								borderColor: "#FFFFFF",
								svg: {
									color: "#FFFFFF",
								},
							},
						},
					},
					"&.user-row": {
						"th, td": {
							background: "var(--primary1-75)",
							color: "#FFFFFF",
							button: {
								borderColor: "#FFFFFF",
								svg: {
									color: "#FFFFFF",
								},
							},
						},
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontFamily: "var(--fontFamilyBase)",
					padding: "8px 0",
					fontStyle: "normal",
					fontWeight: 400,
					fontSize: "14px",
					lineHeight: "140%",
					minHeight: "44px",
				},
			},
		},
	},
};
