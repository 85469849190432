import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {GroupStatus} from "data/enums";
import {inchToAnswersDistance} from "data/utils/helpers";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {Distance, IAnswer, ICorrectAnswer, IGroup} from "data/types/entities";

interface IParams {
	groupId: number;
	answer?: IAnswer;
}

export interface ISummaryItemPlayerAnswerController extends ViewController<IParams> {
	get player(): IPlayer | undefined;

	get groupAnswer(): IAnswer | undefined;

	get isPlayerCorrect(): boolean;

	get isPlayerIncorrect(): boolean;

	get isDistanceCorrect(): boolean;

	get isDistanceIncorrect(): boolean;
}

@injectable()
export class SummaryItemPlayerAnswerController implements ISummaryItemPlayerAnswerController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;
	@observable private _localAnswer: IAnswer | undefined;

	constructor(
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	public get groupAnswer(): IAnswer | undefined {
		return this._localAnswer || this._answerStore.getAnswerByGroupId(this._groupId);
	}

	public get player(): IPlayer | undefined {
		return this._playersStore.getPlayerById(this.groupAnswer?.player || 0);
	}

	public get isDistanceCorrect(): boolean {
		if (this.isAnswerCantBeProceed) {
			return false;
		}
		return this.groupAnswer?.distance === this.correctAnswerDistance;
	}

	public get isDistanceIncorrect(): boolean {
		if (this.isAnswerCantBeProceed) {
			return false;
		}
		return this.groupAnswer?.distance !== this.correctAnswerDistance;
	}

	public get isPlayerCorrect(): boolean {
		if (this.isAnswerCantBeProceed) {
			return false;
		}
		return this.groupAnswer?.player === this.correctAnswer?.player;
	}

	public get isPlayerIncorrect(): boolean {
		if (this.isAnswerCantBeProceed) {
			return false;
		}
		return this.groupAnswer?.player !== this.correctAnswer?.player;
	}

	private get isAnswerCantBeProceed() {
		return !this.groupAnswer || !this.correctAnswer || !this.isComplete;
	}

	private get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	private get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	private get isComplete(): boolean {
		return this.group?.status === GroupStatus.Completed;
	}

	private get correctAnswerDistance(): Distance {
		return inchToAnswersDistance(this.correctAnswer?.distance);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._groupId = param.groupId;
		this._localAnswer = param.answer;
	}
}
