import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {NavigateFunction} from "react-router-dom";
import type {IEventsStore} from "data/stores/events/events.store";
import {GroupStatus} from "data/enums";
import {IAnswer, IGroup} from "data/types/entities";

interface IParams {
	navigate: NavigateFunction;
	groupId: number;
}

export interface ISummaryItemEditController extends ViewController<IParams> {
	goToSliderStep: () => void;

	get isHidden(): boolean;
}

@injectable()
export class SummaryItemEditController implements ISummaryItemEditController {
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _groupId: number | undefined;

	constructor(
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	private get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	private get answer(): IAnswer | undefined {
		if (!this._groupId) {
			return undefined;
		}
		return this._answerStore.getAnswerByGroupId(this._groupId);
	}

	private get hasAnswer(): boolean {
		return Boolean(this.answer);
	}

	private get isLocked(): boolean {
		if (!this.group) {
			return false;
		}
		return [GroupStatus.Active, GroupStatus.Completed].includes(this.group.status);
	}

	public get isHidden(): boolean {
		if (this.isLocked) {
			return true;
		}
		return !this.hasAnswer;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
		this._groupId = param.groupId;
	}

	public goToSliderStep = (): void => {
		if (!this.group) {
			return;
		}

		this._answerStore.clearTieBreaker();
		this._answerStore.sliderStep = this.group.order - 1;
		this._navigate?.("/?edit=true");
	};
}
