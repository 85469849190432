/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {ThemeOptions} from "@mui/material/styles";
import {PaletteColorOptions} from "@mui/material";

declare module "@mui/material/styles" {
	interface CustomPalette {}

	interface Palette extends CustomPalette {
		pure: PaletteColorOptions;
		icon: PaletteColorOptions;
	}

	interface PaletteOptions extends CustomPalette {
		pure: PaletteColorOptions;
		icon: PaletteColorOptions;
	}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#000FE1",
		},
		secondary: {
			main: "#070247",
		},
		error: {
			main: "#ff0000",
		},
		warning: {
			main: "#fff100",
		},
		info: {
			main: "#00d4dc",
		},
		success: {
			main: "#60ff00",
		},
		pure: {
			main: "#070247",
		},
		icon: {
			main: "#FFFFFF",
		},
	},
};
