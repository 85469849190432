import type {IEventsStore} from "data/stores/events/events.store";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import * as React from "react";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";

export interface ILeaderboardFiltersController extends ViewController {
	handleGroupChange: (event: SelectChangeEvent<number>, child: React.ReactNode) => void;

	get group(): ILeaderboardStore["group"];

	get groups(): IEventsStore["groups"];
}

@injectable()
export class LeaderboardFiltersController implements ILeaderboardFiltersController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.LeaderboardStore) private _leaderboardStore: ILeaderboardStore
	) {
		makeAutoObservable(this);
	}

	get group() {
		return this._leaderboardStore.group;
	}

	get round() {
		return this._leaderboardStore.round;
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(): void {
		this.checkRound();
		const roundSubscription$ = reaction(
			() => [this._eventsStore.selectedRound],
			() => this.checkRound()
		);

		this.fetchLeaderboard();
		const subscription$ = reaction(
			() => [this.round, this.group],
			() => this.fetchLeaderboard()
		);
		this._subscriptions$.push(subscription$);
		this._subscriptions$.push(roundSubscription$);
	}

	private checkRound() {
		if (this._eventsStore.selectedRound) {
			this._leaderboardStore.round = this._eventsStore.selectedRound.roundNo;
		}
	}

	public handleGroupChange = (event: SelectChangeEvent<number>) => {
		this._leaderboardStore.group = Number(event.target.value);
	};

	protected fetchLeaderboard() {
		void this._leaderboardStore.fetchLeaderboard();
	}

	get groups(): IEventsStore["groups"] {
		if (!this.round) {
			return this._eventsStore.getGroupsByRoundId(this._eventsStore.roundId);
		}
		return this._eventsStore.getGroupsByRoundId(this.round);
	}
}
