import {Bindings} from "data/constants/bindings";
import {GroupStatus} from "data/enums";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ILeaderboardRank} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";

export interface ILeaderboardTableRowController extends ViewController {
	toggle: () => void;
	getRowUserClass: (row: ILeaderboardRank) => string;

	get isOpen(): boolean;

	get isExpandAvailable(): boolean;
}

@injectable()
export class LeaderboardTableRowController implements ILeaderboardTableRowController {
	@observable private _isOpen: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	get isExpandAvailable(): boolean {
		return this._eventsStore.groups.every((e) =>
			[GroupStatus.Completed, GroupStatus.Active].includes(e.status)
		);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public toggle = (): void => {
		this._isOpen = !this._isOpen;
	};

	public getRowUserClass = (row: ILeaderboardRank): string => {
		return this._userStore.user?.id === row.userId ? "user-row" : "";
	};
}
