import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface IGroupInfoModalController extends ViewController {
	close: () => void;

	get isOpen(): boolean;
}

@injectable()
export class GroupInfoModalController implements IGroupInfoModalController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.getIsModalOpen(ModalType.GROUP_INFO);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};
}
