import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswer, ICorrectAnswer, IGroup} from "data/types/entities";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import {GroupStatus} from "data/enums";

interface IParams {
	groupId: number;
	answer?: IAnswer;
}

export interface ISummaryItemMissAllAnswerController extends ViewController<IParams> {
	get isCorrect(): boolean;

	get isIncorrect(): boolean;
}

@injectable()
export class SummaryItemMissAllAnswerController implements ISummaryItemMissAllAnswerController {
	@observable private _groupId: number = Number.MAX_SAFE_INTEGER;
	@observable private _localAnswer: IAnswer | undefined;

	constructor(
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore
	) {
		makeAutoObservable(this);
	}

	get isCorrect() {
		if (!this.isMissAllAnswered || !this.isComplete) {
			return false;
		}
		return this.correctAnswer?.player === null;
	}

	get isIncorrect() {
		if (!this.isMissAllAnswered || !this.isComplete) {
			return false;
		}
		return this.parsedAnswer !== this.correctAnswer?.player;
	}

	private get isMissAllAnswered(): boolean {
		return this.parsedAnswer === null;
	}

	private get parsedAnswer() {
		return this.answer?.player === 0 ? null : this.answer?.player;
	}

	private get answer(): IAnswer | undefined {
		if (!this._groupId) {
			return undefined;
		}
		return this._localAnswer || this._answerStore.getAnswerByGroupId(this._groupId);
	}

	private get correctAnswer(): null | ICorrectAnswer {
		return this.group?.correctAnswer || null;
	}

	private get group(): IGroup | undefined {
		return this._eventsStore.getGroupById(this._groupId);
	}

	private get isComplete(): boolean {
		return this.group?.status === GroupStatus.Completed;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._groupId = param.groupId;
		this._localAnswer = param.answer;
	}
}
