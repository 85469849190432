import {inject, injectable} from "inversify";
import type {ApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {
	IFetchAnswersRequest,
	ISaveAnswersPayload,
	IShowMyAnswersResponse,
} from "data/types/entities";

export interface IAnswersApiProvider {
	saveAnswers(payload: ISaveAnswersPayload): Promise<ApiResponse<IShowMyAnswersResponse>>;

	getAnswers(params: IFetchAnswersRequest): Promise<ApiResponse<IShowMyAnswersResponse>>;
}

@injectable()
export class AnswersApiProvider implements IAnswersApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getAnswers(payload: IFetchAnswersRequest): Promise<ApiResponse<IShowMyAnswersResponse>> {
		const {eventId, roundId} = payload;
		return this._http.get(`ntp/show_my/${eventId}/${roundId}`);
	}

	saveAnswers(payload: ISaveAnswersPayload): Promise<ApiResponse<IShowMyAnswersResponse>> {
		return this._http.post(`ntp/answer`, payload);
	}
}
