import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {ITutorialStep} from "data/types/tutorial";

export interface ITutorialModalController extends ViewController {
	close: () => void;
	goToStep: (step: number) => void;

	get isFinalStep(): boolean;

	get currentStep(): number;

	get isOpen(): boolean;

	get steps(): ITutorialStep[];
}

@injectable()
export class TutorialModalController implements ITutorialModalController {
	@observable protected _currentStep = 0;

	constructor(@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore) {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._tutorialStore.isTutorialOpen;
	}

	public get steps(): ITutorialStep[] {
		return this._tutorialStore.steps;
	}

	public get currentStep(): number {
		return this._currentStep;
	}

	public get isFinalStep(): boolean {
		return this._currentStep + 1 === this.steps.length;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		if (!this._tutorialStore.getIsTutorialViewedStorage()) {
			this._tutorialStore.openTutorialModal();
		}
	}

	public close = () => {
		this._currentStep = 0;
		this._tutorialStore.closeTutorialModal();
	};

	@action
	public goToStep = (step: number): void => {
		this._currentStep = step;
	};
}
