import {GAME_NAME, GAME_SEASON} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IGigyaStore} from "data/stores/gigya/gigya.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {AxiosApiErrorGeneric} from "data/types/api";
import {ViewController} from "data/types/structure";
import type {IUserLoginPayload} from "data/types/user";
import {inject, injectable} from "inversify";
import {get, isEqual, noop} from "lodash";
import {
	action,
	type IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {getIsUserRegisteredForGame} from "data/utils/helpers";

export interface IGigyaGateController extends ViewController {
	setUserLoginPayload: (payload: IUserLoginPayload | undefined) => void;
	onLogout: () => void;

	get sessionChecked(): unknown;

	get user(): IUserStore["user"];

	get isAuthenticated(): boolean;

	get isChildrenVisible(): boolean;

	get userLoginPayload(): IUserLoginPayload | undefined;
}

@injectable()
export class GigyaGateController implements IGigyaGateController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable protected _isChildrenVisible: boolean = false;

	constructor(
		@inject(Bindings.GigyaStore) private _gigyaStore: IGigyaStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get userLoginPayload(): IUserLoginPayload | undefined {
		return this._gigyaStore.userLoginPayload;
	}

	get isChildrenVisible(): boolean {
		return this._isChildrenVisible;
	}

	get isAuthenticated(): boolean {
		return this._userStore.isAuthorized;
	}

	get sessionChecked(): boolean {
		return this._userStore.isSessionChecked;
	}

	get user(): IUserStore["user"] {
		return this._userStore.user;
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: void): void {
		this.requestCurrentUser();
		this.checkChildrenVisibility();

		this._subscriptions$.push(
			reaction(
				() => this.sessionChecked,
				() => this.checkChildrenVisibility()
			)
		);

		this._subscriptions$.push(
			reaction(
				() => this.user,
				() => this.checkRegisterForGame()
			)
		);

		this._subscriptions$.push(
			reaction(
				() => [this.userLoginPayload, this.sessionChecked, this.user],
				() => this.checkLogin()
			)
		);
	}

	public onLogout = (): void => {
		this.setUserLoginPayload(undefined);

		if (this.isAuthenticated) {
			this.logoutProcess();
		}
	};

	@action
	public setUserLoginPayload = (payload: IUserLoginPayload | undefined): void => {
		this._gigyaStore.userLoginPayload = payload;
	};

	protected requestCurrentUser(): void {
		void this._userStore
			.requestUser()
			.then(() => {
				this.checkRegisterForGame();
			})
			.catch(noop);
	}

	@action
	protected checkRegisterForGame(): void {
		if (!this.user) {
			return;
		}
		if (!getIsUserRegisteredForGame(this.user, GAME_NAME, GAME_SEASON)) {
			this._modalsStore.showModal(ModalType.TERMS);
			this._isChildrenVisible = false;
		} else {
			this._isChildrenVisible = true;
		}
	}

	@action
	protected checkChildrenVisibility(): void {
		runInAction(() => {
			this._isChildrenVisible = this.sessionChecked;
		});
	}

	protected logoutProcess(): void {
		this._userStore.logout().finally(() => {
			window.location.reload();
		});
	}

	protected checkLogin(): void {
		if (this.userLoginPayload && this.sessionChecked && !this.user) {
			this._userStore.login(this.userLoginPayload).catch((e) => {
				const error = e as AxiosApiErrorGeneric;
				const USER_NOT_REGISTERED = isEqual(get(error, "response.status"), 418);
				if (USER_NOT_REGISTERED) {
					this._modalsStore.showModal(ModalType.SECONDARY_REGISTRATION);
				} else {
					this._modalsStore.showAxiosError(error);
				}
			});
		}
	}
}
