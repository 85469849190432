import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
} from "data/stores/static_content/static_content.store";
import type {IPlayer} from "data/stores/players/players.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IRound} from "data/stores/rounds/rounds.store";
import type {ISquad} from "data/stores/squads/squds.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import type {IGeneral} from "data/stores/general/general.store";
import {Language} from "data/enums";
import {IContest, IEvent, IGameEvent} from "data/types/entities";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	rounds(): Promise<AxiosResponse<IRound[]>>;
	players(): Promise<AxiosResponse<IPlayer[]>>;
	playersForEvent(eventId: number): Promise<AxiosResponse<IPlayer[]>>;
	squads(): Promise<AxiosResponse<ISquad[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;
	contests(): Promise<AxiosResponse<IContest[]>>;
	events(): Promise<AxiosResponse<IEvent[]>>;
	singleEvent(eventId: number): Promise<AxiosResponse<IGameEvent>>;
	general(): Promise<AxiosResponse<IGeneral>>;
	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;
	helpSections(locale: string): Promise<AxiosResponse<ISections>>;
	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	general = () => this._jsonClient.get<IGeneral>("ntp/general.json");
	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	rounds = () => this._jsonClient.get<IRound[]>("rounds.json");
	players = () => this._jsonClient.get<IPlayer[]>("players.json");
	playersForEvent = (eventId: number) =>
		this._jsonClient.get<IPlayer[]>(`players/${eventId}.json`);
	squads = () => this._jsonClient.get<ISquad[]>("squads.json");
	checksums = () => this._jsonClient.get<IChecksums>("checksums.json");
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`locale/${locale}.json`);

	contests = () => this._jsonClient.get<IContest[]>("contests.json");
	events = () => this._jsonClient.get<IEvent[]>("events.json");
	singleEvent = (eventId: number) =>
		this._jsonClient.get<IGameEvent>(`ntp/groups/${eventId}.json`);

	helpCategories = (locale: Language) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`);
	helpSections = (locale: Language) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`);
	helpArticles = (locale: Language) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`);
}
