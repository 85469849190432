import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import {deburr, isEqual, orderBy, sortBy, toLower} from "lodash";
import type {
	ISections,
	IStaticContentStore,
	TArticle,
} from "data/stores/static_content/static_content.store";

interface IControllerProps {
	navigate: ReturnType<typeof useNavigate>;
	pathname: string;
}

export interface IHelpController extends ViewController<IControllerProps> {
	i18n: ILocalizationStore;
	updatePathname: (pathname: string) => void;
	buildPathName: (pathname: string) => string;
	isActiveTab: (tabId: number) => boolean;
	isContactUs: (pathname: string) => boolean;

	get tabs(): ISections["sections"];

	get contents(): TArticle[];

	get currentTab(): number | null;

	findSectionsById(sectionId: number): TArticle[];
}

@injectable()
export class HelpController implements IHelpController {
	private static rootPath = "/help";
	private _currentTab: number | null = null;
	private _navigate!: IControllerProps["navigate"];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	get tabs() {
		return sortBy(this._staticContentStore.sections, "position");
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	get currentTab() {
		return this._currentTab;
	}

	public findSectionsById(sectionId: number) {
		const sections = this.contents?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	public updatePathname = (pathname: string) => {
		const newTab = this.tabs.find((it) => pathname.includes(this.buildPathName(it.name)))?.id;
		const isContactUs = pathname.includes("contact_us");
		if (newTab) {
			this.setCurrentTab(newTab);
		}
		if (isContactUs) {
			this.setCurrentTab(999);
		}
	};

	public setCurrentTab = (value: number) => {
		this._currentTab = value;
	};

	public isActiveTab = (tab: number) => {
		return isEqual(this._currentTab, tab);
	};

	public isContactUs = (pathname: string) => {
		return pathname.includes("contact_us") || pathname.includes("contact us");
	};

	public buildPathName = (name: string) => {
		return toLower(deburr(name)).split(" ").join("-");
	};

	async init({navigate, pathname}: IControllerProps) {
		await this._staticContentStore.fetchStaticContent();
		this._navigate = navigate;

		pathname = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;

		if (isEqual(pathname, HelpController.rootPath)) {
			return navigate(this.buildPathName(this.tabs[0].name), {replace: true});
		}

		this.updatePathname(pathname);
	}

	dispose() {
		return;
	}
}
