import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IGigyaStore} from "data/stores/gigya/gigya.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	action,
	type IReactionDisposer,
	makeAutoObservable,
	observable,
	reaction,
	runInAction,
} from "mobx";
import {NavigateFunction} from "react-router-dom";
import {noop} from "lodash";

interface IParams {
	navigate: NavigateFunction;
}

export interface IContestSheetController extends ViewController<IParams> {
	handleSave: () => void;

	get isLastStep(): boolean;
	get isOnlySave(): boolean;
	get isTieBreaker(): boolean;
	get isLoading(): boolean;
}

@injectable()
export class ContestSheetController implements IContestSheetController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _navigate: undefined | IParams["navigate"];
	@observable private _isLoading: boolean = false;
	@observable private _isSaveProcessInProgress: boolean = false;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GigyaStore) private _gigyaStore: IGigyaStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOnlySave(): boolean {
		return this.isLastStep || this.isTieBreaker;
	}

	get isTieBreaker(): boolean {
		return this._answerStore.isTieBreaker;
	}

	protected get isTieBreakerLocked(): boolean {
		return this._eventsStore.isSomeGroupIsLocked;
	}

	protected get hasAllAnswers(): boolean {
		return this._answerStore.hasAllAnswers;
	}

	get isLastStep(): boolean {
		return this._answerStore.sliderStep === this._eventsStore.groupsLength - 1;
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
	}

	private runRouting() {
		if (this.isTieBreaker || (this.isLastStep && this.isTieBreakerLocked)) {
			return false;
		}

		if (!this.isLastStep) {
			this._answerStore.nextStep();

			return true;
		} else if (this.hasAllAnswers && !this.isTieBreakerLocked) {
			this._answerStore.goToTieBreaker();

			return true;
		}

		return false;
	}

	public handleSave = (): void => {
		/**
		 * Returns true if a slide was changed,
		 * otherwise returns false if no further slides exist.
		 */
		const isRouted = this.runRouting();

		if (isRouted) {
			/**
			 * Save user's answers on every slide if he is authorized
			 */
			if (this._userStore.isAuthorized) {
				this.saveProcess(true);
			}
		} else {
			/**
			 * Show registration modal if a user isn't authorized
			 * or redirect to the Summary page once all answers are saved after the last slide.
			 */
			this.checkBeforeSave();
		}
	};

	protected checkBeforeSave() {
		if (!this._userStore.isAuthorized) {
			this.initAuthSaveListener();
			this._gigyaStore.openAuthModal();
			return;
		}

		this.saveProcess();
	}

	protected saveProcess(silent?: boolean): void {
		this._isLoading = true;

		void this._answerStore
			.saveAnswers()
			.catch(noop)
			.then(() => {
				runInAction(() => {
					this._isLoading = false;
				});

				if (silent) return;

				setTimeout(() => {
					if (!this.hasAllAnswers) {
						this._modalsStore.showModal(ModalType.NOT_ALL_ANSWERS);
					} else {
						this._navigate?.("/summary");
					}
				}, 250);
			});
	}

	@action
	protected initAuthSaveListener(): void {
		this._isSaveProcessInProgress = true;
		this.checkSaveListener();

		this._subscriptions$.push(
			reaction(
				() => [
					this._isSaveProcessInProgress,
					this._userStore.isAuthorized,
					this._userStore.isRegisteredForGame,
				],
				() => this.checkSaveListener()
			)
		);
	}

	protected checkSaveListener() {
		const {isAuthorized, isRegisteredForGame} = this._userStore;
		if (!this._isSaveProcessInProgress || !isAuthorized || !isRegisteredForGame) {
			return;
		}

		this.saveProcess();
	}
}
