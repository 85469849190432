import {GameBarStore, IGameBarStore} from "data/stores/game_bar/game_bar.store";
import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {GameBarController, IGameBarController} from "views/components/gameBar/gameBar.controller";
import {
	ILeaderboardAnswerBlockController,
	LeaderboardAnswerBlockController,
} from "views/components/leaderboardTable/leaderboardAnswerBlock/leaderboardAnswerBlock.controller";
import {
	ILeaderboardTableController,
	LeaderboardTableController,
} from "views/components/leaderboardTable/leaderboardTable.controller";
import {
	ISecondaryRegistrationModalController,
	SecondaryRegistrationModalController,
} from "views/components/modals/secondaryRegistrationModal/secondaryRegistrationModal.controller";
import {
	ISummaryItemDistanceController,
	SummaryItemDistanceController,
} from "views/components/summaryItemDistance/summaryItemDistance.controller";
import {
	IVoteAccordionDistanceController,
	VoteAccordionDistanceController,
} from "views/components/voteAccordionDistance/voteAccordionDistance.controller";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {type IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {type IPlayersStore, PlayersStore} from "data/stores/players/players.store";
import {type ISquadsStore, SquadsStore} from "data/stores/squads/squds.store";
import {type IGeneralStore, GeneralStore} from "data/stores/general/general.store";

import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {GigyaStore, IGigyaStore} from "data/stores/gigya/gigya.store";
import {
	ILoginModalController,
	LoginModalController,
} from "views/components/modals/loginModal/loginModal.controller";
import {
	IRegisterModalController,
	RegisterModalController,
} from "views/components/modals/registerModal/registerModal.controller";
import {
	AuthModalControllerController,
	IAuthModalControllerController,
} from "views/components/modals/authModal/authModal.controller";
import {
	GigyaGateController,
	IGigyaGateController,
} from "views/components/gigyaGate/gigyaGate.controller";
import {
	ITieBreakerController,
	TieBreakerController,
} from "views/components/tieBreaker/tieBreaker.controller";
import {
	ISliderStepsController,
	SliderStepsController,
} from "views/components/sliderSteps/sliderSteps.controller";
import {AnswerStore, IAnswerStore} from "data/stores/answer/answer.store";
import {
	ContestSliderController,
	IContestSliderController,
} from "views/components/contestSlider/contestSlider.controller";
import {
	ContestSheetController,
	IContestSheetController,
} from "views/components/contestSheet/contestSheet.controller";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";
import {IMenuController, MenuController} from "views/components/menu/menu.controller";
import {IMenuStore, MenuStore} from "data/stores/menu/menu.store";
import {
	IVoteAccordionController,
	VoteAccordionController,
} from "views/components/voteAccordion/voteAccordion.controller";
import {EventsStore, IEventsStore} from "data/stores/events/events.store";
import {ViewController} from "data/types/structure";
import {HOCMainController} from "views/components/HOCMain/HOCMain.controller";
import {
	IPredictionsController,
	PredictionsController,
} from "views/pages/predictions/predictions.controller";
import {AnswersApiProvider, IAnswersApiProvider} from "data/providers/api/answers.api.provider";
import {IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	GlobalErrorModalController,
	IGlobalErrorModalController,
} from "views/components/modals/globalErrorModal/globalErrorModal.controller";
import {
	INotAllAnswersModalController,
	NotAllAnswersModalController,
} from "views/components/modals/notAllAnswersModal/notAllAnswersModal.controller";
import {
	ILeaveModalController,
	LeaveModalController,
} from "views/components/modals/leaveModal/leaveModal.controller";
import {
	IIncompleteAnswerModalController,
	IncompleteAnswerModalController,
} from "views/components/modals/incompleteAnswerModal/incompleteAnswerModal.controller";
import {
	ISummaryPageController,
	SummaryPageController,
} from "views/pages/summary/summary.page.controller";
import {
	ISummaryItemController,
	SummaryItemController,
} from "views/components/summaryItem/summaryItem.controller";
import {
	ISummaryTieBreakerController,
	SummaryTieBreakerController,
} from "views/components/summaryTieBreaker/summaryTieBreaker.controller";
import {
	GroupInfoModalController,
	IGroupInfoModalController,
} from "views/components/modals/groupInfoModal/groupInfoModal.controller";
import {
	ITermsModalController,
	TermsModalController,
} from "views/components/modals/termsModal/termsModal.controller";
import {
	ILeaderboardTableRowController,
	LeaderboardTableRowController,
} from "views/components/leaderboardTable/tableRow/leaderboardTableRow.controller";
import {
	ILeaderboardApiProvider,
	LeaderboardApiProvider,
} from "data/providers/api/leaderboard.api.provider";
import {ILeaderboardStore, LeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import {
	ILeaderboardFiltersController,
	LeaderboardFiltersController,
} from "views/components/leaderboardFilters/leaderboardFilters.controller";
import {
	ILeaderboardLoadMoreController,
	LeaderboardLoadMoreController,
} from "views/components/leaderboardLoadMore/leaderboardLoadMore.controller";
import {ISharingController, SharingController} from "views/components/sharing/sharing.controller";
import {
	HoleInfoController,
	IHoleInfoController,
} from "views/components/contestPoints/holeInfo.controller";
import {
	AnswerButtonController,
	IAnswerButtonController,
} from "views/components/voteAccordion/answerButton/answerButton.controller";
import {
	GroupLabelController,
	IGroupLabelController,
} from "views/components/groupLabel/groupLabel.controller";
import {
	ISummaryItemAnswerController,
	SummaryItemAnswerController,
} from "views/components/summaryItemAnswer/summaryItemAnswer.controller";
import {
	IOffSeasonController,
	OffSeasonController,
} from "views/pages/off_season/off_season.controller";
import {
	IOffSeasonCheckController,
	OffSeasonCheckController,
} from "views/components/offseason/off_season_check.controller";
import {
	AccordionPillController,
	IAccordionPillController,
} from "views/components/accordionPill/accordionPill.controller";
import {
	ITutorialModalController,
	TutorialModalController,
} from "views/components/modals/tutorialModal/tutorialModal.controller";
import {ITutorialStore, TutorialStore} from "data/stores/tutorial/tutorial.store";
import {
	IRoundPlayModalController,
	RoundPlayModalController,
} from "views/components/modals/roundPlayModal/roundPlayModal.controller";
import {
	IRoundWatchApiProvider,
	RoundWatchApiProvider,
} from "data/providers/api/round_watch.provider";
import {ILiveWatchingStore, LiveWatchingStore} from "data/stores/live_watching/live_watching.store";
import {
	ISuccessModalController,
	SuccessModalController,
} from "views/components/modals/successModal/successModal.controller";
import {
	ISummaryItemNoAnswerController,
	SummaryItemNoAnswerController,
} from "views/components/summaryItemAnswer/summary_item_no_answer/summary_item_no_answer.controller";
import {
	ISummaryItemEditController,
	SummaryItemEditController,
} from "views/components/summaryItemAnswer/summary_item_edit/summary_item_edit.controller";
import {
	ISummaryItemPlayerAnswerController,
	SummaryItemPlayerAnswerController,
} from "views/components/summaryItemAnswer/summary_item_player_answer/summary_item_player_answer.controller";
import {
	ISummaryItemMissAllAnswerController,
	SummaryItemMissAllAnswerController,
} from "views/components/summaryItemAnswer/summary_item_miss_all_answer/summary_item_miss_all_answer.controller";
import {
	ISummaryItemResultController,
	SummaryItemResultController,
} from "views/components/summary_item_result/summary_item_result.controller";
import {
	ISummaryRoundSelectorController,
	SummaryRoundSelectorController,
} from "views/components/summary_round_selector/summary_round_selector.controller";
import {ISummaryStore, SummaryStore} from "data/stores/summary/summary.store";
import {
	ISummaryContentToggleController,
	SummaryContentToggleController,
} from "views/components/summary_content_toggle/summary_content_toggle.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IAnswersApiProvider>(Bindings.AnswersApiProvider).to(AnswersApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaderboardApiProvider>(Bindings.LeaderboardApiProvider).to(LeaderboardApiProvider);
	bind<IRoundWatchApiProvider>(Bindings.RoundWatchApiProvider).to(RoundWatchApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();
	bind<IGeneralStore>(Bindings.GeneralStore).to(GeneralStore).inSingletonScope();
	bind<IGigyaStore>(Bindings.GigyaStore).to(GigyaStore).inSingletonScope();
	bind<IAnswerStore>(Bindings.AnswerStore).to(AnswerStore).inSingletonScope();
	bind<IMenuStore>(Bindings.MenuStore).to(MenuStore).inSingletonScope();
	bind<IEventsStore>(Bindings.EventsStore).to(EventsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore).inSingletonScope();
	bind<IStaticContentStore>(Bindings.StaticContentStore)
		.to(StaticContentStore)
		.inSingletonScope();

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<ILeaderboardStore>(Bindings.LeaderboardStore).to(LeaderboardStore).inSingletonScope();
	bind<IGameBarStore>(Bindings.GameBarStore).to(GameBarStore).inSingletonScope();
	bind<ITutorialStore>(Bindings.TutorialStore).to(TutorialStore).inSingletonScope();
	bind<ILiveWatchingStore>(Bindings.LiveWatchingStore).to(LiveWatchingStore).inSingletonScope();
	bind<ISummaryStore>(Bindings.SummaryStore).to(SummaryStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<ILoginModalController>(Bindings.LoginModalController).to(LoginModalController);
	bind<IRegisterModalController>(Bindings.RegisterModalController).to(RegisterModalController);
	bind<IAuthModalControllerController>(Bindings.AuthModalControllerController).to(
		AuthModalControllerController
	);
	bind<IGigyaGateController>(Bindings.GigyaGateController).to(GigyaGateController);
	bind<ITieBreakerController>(Bindings.TieBreakerController).to(TieBreakerController);
	bind<ISliderStepsController>(Bindings.SliderStepsController).to(SliderStepsController);
	bind<IContestSliderController>(Bindings.ContestSliderController).to(ContestSliderController);
	bind<IContestSheetController>(Bindings.ContestSheetController).to(ContestSheetController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IMenuController>(Bindings.MenuController).to(MenuController);
	bind<IVoteAccordionController>(Bindings.VoteAccordionController).to(VoteAccordionController);
	bind<ViewController>(Bindings.HOCMainController).to(HOCMainController);
	bind<IPredictionsController>(Bindings.PredictionsController).to(PredictionsController);
	bind<IGlobalErrorModalController>(Bindings.GlobalErrorModalController).to(
		GlobalErrorModalController
	);
	bind<INotAllAnswersModalController>(Bindings.NotAllAnswersModalController).to(
		NotAllAnswersModalController
	);
	bind<ILeaveModalController>(Bindings.LeaveModalController).to(LeaveModalController);
	bind<IIncompleteAnswerModalController>(Bindings.IncompleteAnswerModalController).to(
		IncompleteAnswerModalController
	);
	bind<ISummaryPageController>(Bindings.SummaryPageController).to(SummaryPageController);
	bind<ISummaryItemController>(Bindings.SummaryItemController).to(SummaryItemController);
	bind<ISummaryTieBreakerController>(Bindings.SummaryTieBreakerController).to(
		SummaryTieBreakerController
	);
	bind<IGroupInfoModalController>(Bindings.GroupInfoModalController).to(GroupInfoModalController);
	bind<ITermsModalController>(Bindings.TermsModalController).to(TermsModalController);
	bind<ILeaderboardTableRowController>(Bindings.LeaderboardTableRowController).to(
		LeaderboardTableRowController
	);
	bind<ILeaderboardFiltersController>(Bindings.LeaderboardFiltersController).to(
		LeaderboardFiltersController
	);
	bind<ILeaderboardLoadMoreController>(Bindings.LeaderboardLoadMoreController).to(
		LeaderboardLoadMoreController
	);
	bind<ISharingController>(Bindings.SharingController).to(SharingController);
	bind<IHoleInfoController>(Bindings.HoleInfoController).to(HoleInfoController);
	bind<IAnswerButtonController>(Bindings.AnswerButtonController).to(AnswerButtonController);
	bind<IGroupLabelController>(Bindings.GroupLabelController).to(GroupLabelController);
	bind<ISummaryItemAnswerController>(Bindings.SummaryItemAnswerController).to(
		SummaryItemAnswerController
	);
	bind<ISummaryItemDistanceController>(Bindings.SummaryItemDistanceController).to(
		SummaryItemDistanceController
	);
	bind<ILeaderboardAnswerBlockController>(Bindings.LeaderboardAnswerBlockController).to(
		LeaderboardAnswerBlockController
	);
	bind<ISecondaryRegistrationModalController>(Bindings.SecondaryRegistrationModalController).to(
		SecondaryRegistrationModalController
	);
	bind<ILeaderboardTableController>(Bindings.LeaderboardTableController).to(
		LeaderboardTableController
	);
	bind<IGameBarController>(Bindings.GameBarController).to(GameBarController);
	bind<IOffSeasonController>(Bindings.OffSeasonController).to(OffSeasonController);
	bind<IOffSeasonCheckController>(Bindings.OffSeasonCheckController).to(OffSeasonCheckController);
	bind<IVoteAccordionDistanceController>(Bindings.VoteAccordionDistanceController).to(
		VoteAccordionDistanceController
	);
	bind<IAccordionPillController>(Bindings.AccordionPillController).to(AccordionPillController);
	bind<ITutorialModalController>(Bindings.TutorialModalController).to(TutorialModalController);
	bind<IRoundPlayModalController>(Bindings.RoundPlayModalController).to(RoundPlayModalController);
	bind<ISuccessModalController>(Bindings.SuccessModalController).to(SuccessModalController);
	bind<ISummaryItemNoAnswerController>(Bindings.SummaryItemNoAnswerController).to(
		SummaryItemNoAnswerController
	);
	bind<ISummaryItemEditController>(Bindings.SummaryItemEditController).to(
		SummaryItemEditController
	);
	bind<ISummaryItemPlayerAnswerController>(Bindings.SummaryItemPlayerAnswerController).to(
		SummaryItemPlayerAnswerController
	);
	bind<ISummaryItemMissAllAnswerController>(Bindings.SummaryItemMissAllAnswerController).to(
		SummaryItemMissAllAnswerController
	);
	bind<ISummaryItemResultController>(Bindings.SummaryItemResultController).to(
		SummaryItemResultController
	);
	bind<ISummaryRoundSelectorController>(Bindings.SummaryRoundSelectorController).to(
		SummaryRoundSelectorController
	);
	bind<ISummaryContentToggleController>(Bindings.SummaryContentToggleController).to(
		SummaryContentToggleController
	);
});
