import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {SummaryPageContent} from "data/enums";

export interface ISummaryStore {
	set summarySelection(value: SummaryPageContent);

	get summarySelection(): SummaryPageContent;
}

@injectable()
export class SummaryStore implements ISummaryStore {
	@observable private _summarySelection: SummaryPageContent = SummaryPageContent.Picks;

	constructor() {
		makeAutoObservable(this);
	}

	public get summarySelection(): SummaryPageContent {
		return this._summarySelection;
	}

	public set summarySelection(value: SummaryPageContent) {
		this._summarySelection = value;
	}
}
