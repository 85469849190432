import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface ILeaveModalController extends ViewController {
	get isBlocked(): boolean;
	stay: () => void;
	leave: () => void;
	close: () => void;
}

@injectable()
export class LeaveModalController implements ILeaveModalController {
	constructor(
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public leave = (): void => {
		this.clearAnswersFlow();
		this.close();
	};

	public stay = (): void => {
		this.clearAnswersFlow();
		this.close();
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	protected clearAnswersFlow() {
		this._answerStore.clearTieBreaker();
		this._answerStore.sliderStep = 0;
	}

	get isBlocked(): boolean {
		return (
			this._answerStore.areAnswersChanged &&
			!this._modalsStore.getIsModalOpen(ModalType.NOT_ALL_ANSWERS)
		);
	}
}
