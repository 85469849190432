import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IAnswerStore} from "data/stores/answer/answer.store";
import type {IEventsStore} from "data/stores/events/events.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {IGroup} from "data/types/entities";
import {ViewController} from "data/types/structure";
import {GroupHelper} from "data/utils/GroupHelper";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {NavigateFunction} from "react-router-dom";
import {noop} from "lodash";

interface IParams {
	navigate: NavigateFunction;
	isEditMode: boolean;
}

export interface IPredictionsController extends ViewController<IParams> {
	get isLoading(): boolean;

	updateEditMode: (editMode: boolean) => void;
}

@injectable()
export class PredictionsController implements IPredictionsController {
	@observable private _isEditMode: boolean = false;
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _navigate: undefined | IParams["navigate"];

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.AnswerStore) protected _answerStore: IAnswerStore,
		@inject(Bindings.EventsStore) private _eventsStore: IEventsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return false;
	}

	protected get groups(): IGroup[] {
		return this._eventsStore.groups;
	}

	protected get isAllGroupsActiveOrComplete(): boolean {
		return this.groups.every((e) => GroupHelper.IS_GROUP_LOCKED(e));
	}

	@action
	public updateEditMode = (editMode: boolean) => {
		this._isEditMode = editMode;
	};

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
		this._isEditMode = param.isEditMode;
		this.checkAnswersSubscribe();
		this.checkRedirectSubscribe();
		setTimeout(() => {
			this.checkStep();
		}, 750);
	}

	protected checkRedirectSubscribe(): void {
		this.checkRedirect();
		const subscription$ = reaction(
			() => [
				this.isAllGroupsActiveOrComplete,
				this._answerStore.savedAnswersLength,
				this._userStore.isAuthorized,
				this._isEditMode,
				this._modalsStore.modal,
			],
			() => this.checkRedirect()
		);

		this._subscriptions$.push(subscription$);
	}

	protected checkStep(): void {
		const nearestGroupIndex = this.groups.findIndex((e) => {
			return (
				GroupHelper.IS_GROUP_SCHEDULED(e) &&
				!this._answerStore.getAnswerByGroupId(e.groupId)
			);
		});

		if (this._isEditMode) {
			return;
		}

		if (nearestGroupIndex === -1) {
			this._answerStore.sliderStep = this.groups.length - 1;
			return;
		}

		this._answerStore.sliderStep = nearestGroupIndex;
	}

	protected checkAnswersSubscribe() {
		this.checkFetchAnswers();

		const subscription$ = reaction(
			() => [
				this._eventsStore.nearestEvent,
				this._eventsStore.roundId,
				this._userStore.isAuthorized,
			],
			() => this.checkFetchAnswers()
		);

		this._subscriptions$.push(subscription$);
	}

	protected checkFetchAnswers() {
		if (!this._userStore.isAuthorized || !this._userStore.isRegisteredForGame) {
			return;
		}

		if (this._eventsStore.nearestEvent && this._eventsStore.roundId) {
			void this._answerStore.fetchAnswers().catch(noop);
		}
	}

	/*
	 * Method redirects only logged-in user to summary,
	 * if user has at least one answer or all groups are completed
	 */
	protected checkRedirect() {
		const isNonAllAnswersModalOpen = this._modalsStore.getIsModalOpen(
			ModalType.NOT_ALL_ANSWERS
		);
		if (!this._userStore.isAuthorized || this._isEditMode || isNonAllAnswersModalOpen) {
			return;
		}

		if (this.isAllGroupsActiveOrComplete || this._answerStore.savedAnswersLength > 0) {
			this._navigate?.("/summary");
		}
	}
}
