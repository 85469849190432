import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";
import {IUserLoginPayload} from "data/types/user";

export interface ILoginPayload {
	email: string;
	password: string;
	lang: Language;
}

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	login: (params: IUserLoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = (params: IUserLoginPayload) =>
		// this._http.post<TLoginResponse>("auth/login/sso", {...params, game: GAME_NAME});
		this._http.post<TLoginResponse>("auth/login/sso", {...params});

	logout = () => this._http.post("auth/logout");
}
