import {GAME_NAME} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IGigyaStore} from "data/stores/gigya/gigya.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {AxiosApiErrorGeneric} from "data/types/api";
import {ViewController} from "data/types/structure";
import {IUserRegisterPayload} from "data/types/user";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import React from "react";

interface IFormGroup extends HTMLFormElement {
	username: HTMLInputElement;
	opt_in: HTMLInputElement;
	terms: HTMLInputElement;
}

export interface ISecondaryRegistrationModalController extends ViewController {
	onSubmit: (event: React.SyntheticEvent<IFormGroup>) => void;

	get isOpen(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class SecondaryRegistrationModalController implements ISecondaryRegistrationModalController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.GigyaStore) private _gigyaStore: IGigyaStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.getIsModalOpen(ModalType.SECONDARY_REGISTRATION);
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.closeGigya();

		const subscription = reaction(
			() => this.isOpen,
			() => this.closeGigya()
		);

		this._subscriptions$.push(subscription);
	}

	public onSubmit = (event: React.SyntheticEvent<IFormGroup>): void => {
		event.preventDefault();
		event.stopPropagation();

		const {username, opt_in, terms} = event.currentTarget;

		if (!terms.checked || !this._gigyaStore.userLoginPayload) {
			return;
		}

		const payload: IUserRegisterPayload = {
			...this._gigyaStore.userLoginPayload,
			username: username.value,
			opt_in: opt_in.checked,
			game: GAME_NAME,
		};

		this._isLoading = true;
		this._userStore
			.register(payload)
			.then(() => {
				this._modalsStore.hideModal();
			})
			.catch((e) => {
				this._modalsStore.showAxiosError(e as AxiosApiErrorGeneric);
			})
			.finally(() => {
				this._isLoading = false;
			});
	};

	protected closeGigya(): void {
		if (!this.isOpen) {
			return;
		}

		this._gigyaStore.closeAuthModal();
		this._gigyaStore.closeLoginModal();
		this._gigyaStore.closeRegisterModal();
	}
}
