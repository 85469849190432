import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Container} from "views/components/common/layout";
import {ReactComponent as Logo} from "assets/img/logo_stack.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {Button} from "@mui/material";
import {MenuComponent} from "views/components/menu/menu.component";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/header/header.controller";
import {Bindings} from "data/constants/bindings";
import {NavLink} from "react-router-dom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {Exist} from "views/components/exist/exist.component";
import {SITE_URL} from "data/constants";

const HeaderElement = styled.header`
	width: 100%;
	background: linear-gradient(121.08deg, #070247 19.52%, #000fe1 73.19%);
	height: 56px;
	padding: 12px 0;

	> div {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
`;

interface ISubHeaderProps {
	color?: string;
}

const SubHeader = styled.div<ISubHeaderProps>`
	background: ${(props) => props.color || "#000"};
	height: 56px;
	min-height: 56px;
	width: 100%;
	padding: 12px 16px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-height: 100%;
		width: 100%;
		height: auto;
	}
`;

const LeftSide = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 28px;
	color: #ffffff;
	font-weight: 700;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	text-transform: uppercase;

	a {
		position: relative;
		top: 4px;
		display: block;
		height: 100%;

		svg {
			height: 32px;
		}
	}
`;
const RightSide = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 12px;

	button {
		color: #ffffff;
	}

	.share-icon svg {
		margin-right: 2px;
	}
`;

export const Header: React.FC = observer(() => {
	const {isMenuOpen, isInSeason, settings, toggleMenu, openTutorial} =
		useViewController<IHeaderController>(Bindings.HeaderController);

	return (
		<React.Fragment>
			<HeaderElement>
				<Container>
					<LeftSide>
						<NavLink to="/">
							<Logo className="logo" />
						</NavLink>
					</LeftSide>
					<Exist when={isInSeason}>
						<RightSide>
							<Button color="icon" onClick={openTutorial}>
								<QuestionMarkIcon />
							</Button>
							<Button color="icon" onClick={toggleMenu}>
								{isMenuOpen ? <CloseIcon /> : <MenuIcon />}
							</Button>
						</RightSide>
					</Exist>
				</Container>
			</HeaderElement>
			<Exist when={isInSeason}>
				<Exist when={settings?.logo}>
					<SubHeader color={settings?.color}>
						<img src={SITE_URL + (settings?.logo || "")} alt="" role="banner" />
					</SubHeader>
				</Exist>
				<MenuComponent />
			</Exist>
		</React.Fragment>
	);
});
